<template>
  <div class="BottomBar">
    <div class="buttons">
      <div class="startLearnButtonsContainer">
        <div
          v-show="cardState==='ready' || cardState==='initial'"
          class="learnMoreButton"
          @click="$emit('open-popout-window')">
          {{ $t('learnMoreButton') }}
        </div>
        <div
          v-show="cardState!=='editing' || cardState==='initial'"
          class="startButton"
          @click="startButton()">
          {{ $t(StartButtonTitle) }}
        </div>
      </div>
      <div class="skipButtonContainer">
        <div
          v-show="cardState==='ready' || cardState==='initial'"
          class="skipButton"
          @click="skipButton()">
          {{ $t('skipAnimationButton') }}
        </div>
      </div>
    </div>
  </div>
</template>

<translations>
  learnMoreButton: 'Learn more'
  learnMoreButton_no: 'Lær mer'
  skipAnimationButton: 'Skip animation'
  skipAnimationButton_no: 'Dropp animasjon'
  startButton: 'Start'
  nextButton: 'Next'
  nextButton_no: 'Neste'
  memoHomeButton: 'Memolife home'
  memoHomeButton_no: 'Memolife hjem'

</translations>

<script>
export default {
  props: {
    cardState: {
        type: String,
        default: 'initial',
    },
    step: Number,
  },
  data(){
      return {
       
      };
  },
  computed: {
    StartButtonTitle(){
      if(this.cardState==='initial' || this.cardState==='ready'){
        return 'startButton'
      } else if(this.cardState==='done') {
          return 'nextButton'
      } else {
        return 'memoHomeButton'
      }
    }
  },
  methods: {
      startButton(){
        if(this.cardState==='initial'||this.cardState==='ready'){
          this.$emit('start-card')
        } else if(this.cardState==='done'||this.cardState==='diaryDone'){
            this.$emit('next-card')
        } 
      },
      skipButton(){
        this.$emit('skip-animation')
      }
  },
}
</script>

<style scoped>
.BottomBar {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 1em;
    padding-left: 2em;
    padding-right: 2em;
    /* border: 2px solid blue; */
    bottom: 0;
    width: 100%;
    height: 7em;
}
.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.startLearnButtonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 19em;
}
.title {
  font-size: 34px;
  font-weight: 600;
  color: white;
}
.sub {
  font-size: 14px;
  color: white;
}
.startButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color:rgb(255, 255, 255);
    background:rgb(100, 203, 225);
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    border-radius: 3px;
    width: 9em;
    height: 2.5em;
    margin-bottom: 0.5em;
    padding-right: 0.5em;
    padding-left: 0.5em;
}
.startButton.disabled {
  visibility: hidden;
}
.startButton:hover {
  cursor: pointer;
}
.learnMoreButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color:rgba(180, 252, 255, 0.623);
    background:rgba(100, 202, 225, 0);
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    border-radius: 3px;
    border: 1px solid rgba(100, 202, 225, 0.644);
    width: 9em;
    height: 2.5em;
    margin-bottom: 0.5em;
    margin-right: 1em;
    padding-right: 0.5em;
    padding-left: 0.5em;
}
.learnMoreButton.disabled {
  visibility: hidden;
}
.learnMoreButton:hover {
  cursor: pointer;
  border: 1px solid rgb(182, 242, 255);
  color:rgba(180, 253, 255, 0.973);
}
.skipButtonContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items:flex-end;
    background:rgba(37, 181, 200, 0);
    text-align: center;
    width: 100%;
}
.skipButton {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    color:rgba(255, 255, 255, 0.527);
    background:rgba(37, 181, 200, 0);
    text-align: center;
    font-size: 60%;
    font-weight: 300;
    padding: 1em;
    margin-right: 3.1em;
}
.skipButton.disabled {
  visibility: hidden;
}
.skipButton:hover {
  cursor: pointer;
}
</style>
