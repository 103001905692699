<template>
  <div class="TopBar">
    <div
      class="icon"
      :class="{ active: joyDone || index === 0 }"
      @click="index !== 0 ? select(0) : false">
      <Icons :step="stepJoy" :active="index === 0" />
    </div>
    <div
      class="icon"
      :class="{ active: frogsDone || index === 1 }"
      @click="index !== 1 ? select(1) : false">
      <Icons :step="stepFrog" :active="index === 1" />
    </div>
    <div
      class="icon"
      :class="{ active: focusDone || index === 2 }"
      @click="index !== 2 ? select(2) : false">
      <Icons :step="stepFocus" :active="index === 2" />
    </div>
    <div
      class="icon"
      :class="{ active: planDone || index === 3 }"
      @click="index !== 3 ? select(3) : false">
      <Icons :step="stepPlan" :active="index === 3" />
    </div>
    <div
      class="icon"
      :class="{ active: diaryDone || index === 4 }"
      @click="index !== 4 ? select(4) : false">
      <Icons :step="stepDiary" :active="index === 4" />
    </div>
  </div>
</template>

<script>
import Icons from '../components/TopBarAnimations.vue';

export default {
  components: {
    Icons,
  },
  props: {
    index: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      stepJoy: 1,
      stepFrog: 2,
      stepFocus: 3,
      stepPlan: 4,
      stepDiary: 5,
    };
  },
  computed: {
    joyDone() {
      return this.$store.getters['moduleMentalhygiene/areJoysFinishedToday'];
    },
    frogsDone() {
      return this.$store.getters['moduleMentalhygiene/areFrogsFinishedToday'];
    },
    focusDone() {
      return this.$store.getters['moduleMentalhygiene/areFocusChecksFinishedToday'];
    },
    planDone() {
      return this.$store.getters["moduleMentalhygiene/isDayplanFinishedToday"];
    },
    diaryDone() {
      return this.$store.getters['moduleMentalhygiene/areDiariesFinishedToday'];
    },
  },
  mounted() {
  },
  methods: {
    select(index) {
      this.$emit('select', index);
    },
  },
};
</script>

<style scoped lang="scss">
.TopBar {
  /* border: 2px solid blue; */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2em;
  height: 2em;
  margin: 1.5em 1.45em 0 1.45em;
  opacity: 0.3;
  @media (max-width: 620px) {
    margin: 1.5em 1.2em 0 1.2em;
    width: 1.5em;
    height: 1.5em;
  }

  @media (max-width: 479px) {
    margin: 1.5em 1.1em 2em 1.1em;
    width: 1.3em;
    height: 1.3em;
  }
}
.icon.active {
  opacity: 1;
}
.icon:hover {
  cursor: pointer;
}
/* .icon:hover {
  cursor: pointer;
} */
</style>
