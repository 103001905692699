<template>
  <div class="JoyContainer">
    <div v-show="showingTitle" class="titleContainer">
      <div class="categoryTitle">
        {{ $t('categoryTitle') }}
      </div>
      <div class="title">
        {{ $t('title') }}
      </div>
    </div>
    <div class="cards">
      <transition-group name="slide">
        <div
          class="card-container"
          v-for="(joy, index) of joys"
          :style="cardStyle(index)"
          :class="{ active: activeCardIndex === index }"
          :key="joy.nodeId"
          @click="shuffle(index)">

          <JoyCard
            v-model="joy.joy"
            :key="joy.nodeId"
            :class="{ active: activeCardIndex === index }"
            :active="activeCardIndex === index"
            :play-card-animation="showTitle"
            :image="joy.image"
            :can-shuffle="canShufflePhotos"
            @shuffle="onShufflePhoto"
            @posted="onPosted"
            @editing="onEditing"/>
        </div>
      </transition-group>
    </div>

    <div class="spacer_main"></div>

    <div v-show="editing" @click="updateJoy()" class="postButton">
      {{ $t('save') }}
    </div>

    <div v-show="!editing && showingTitle" class="buttons">
      <div class="stepsCountText"> {{ $t('joy') }} {{ cardCount }}/{{ max }} </div>

      <div class="prNextButtonsContainer">
        <div @click="deleteJoy()" :class="{ fade: cardCount === 0 }" class="previousButton">
          <img src="../assets/buttons/minus_button_mentalhygiene.svg" height="60px" width="60px" />
        </div>

        <div class="nextButton" :class="{ fade: !canAdd }" @click="addJoy(), cardCount">
          <img src="../assets/buttons/plus_button_mentalhygiene.svg" height="60px" width="60px" />
        </div>
      </div>

      <div class="doneButton" :class="{active:cardCount}" @click="completed()">
        {{ $t('doneButton') }}
      </div>
    </div>
  </div>
</template>

<translations>
  categoryTitle: 'MOTIVATION'
  categoryTitle_no: 'MOTIVASJON'
  title: 'What can you be happy about today?'
  title_no: 'Hva kan du glede deg over i dag?'
  doneButton: 'Done'
  doneButton_no: 'Ferdig'
  save: Save
  save_no: Lagre
  joy: Joy
  joy_no: Glede
</translations>

<script>
import JoyCard from './JoyCard.vue';
import { mapGetters, mapActions } from 'vuex';
import { photoSearch } from '@/sharing';

export default {
  components: {
    JoyCard,
  },
  props: {
    showTitle: Boolean,
  },
  data() {
    return {
      max: 3,
      showingTitle: false,
      activeCardIndex: 0,
      editing: false,
      joyPhotoIndex: 0,
      images: [],
    };
  },

  computed: {
    ...mapGetters({
      joys: 'moduleMentalhygiene/todaysJoys',
    }),

    cardCount() {
      return this.joys.length;
    },

    canAdd() {
      return this.joys.length < this.max && this.joys.filter(j => j.joy).length === this.joys.length;
    },

    canShufflePhotos() {
      return this.images.length > 0;
    },

  },
  watch: {
    showTitle(v){
      if (v) {
        setTimeout(() => {
          this.showingTitle = true;
        }, 1000);
      } 
    },
    activeCardIndex(v) {
      this.images = [];
    }
  },
  mounted() {
    this.cardCount === 0 ? this.addJoy() : false;
    if(this.showTitle){
       this.showingTitle = true
    }
  },

  methods: {
    cardStyle(index) {
      let zIndex = 1000 + index;
      if (this.activeCardIndex === 0 && index === 1) {
        zIndex = 1000 + index + 2;
      }
      const x = 50 * index;
      const r = 3 * index;
      return {
        zIndex,
        transform: `rotate(${r}deg) translateX(${x}px)`,
      };
    },

    deleteJoy() {
      const j = this.joys[this.activeCardIndex];
      if (j !== undefined) {
        this.$store.dispatch('moduleMentalhygiene/deleteJoy', this.joys[this.activeCardIndex].nodeId);
      }
      this.activeCardIndex = this.joys.length - 1;
    },

    updateJoy() {
      this.editing = false;
      const j = this.joys[this.activeCardIndex];
      this.$store.dispatch('moduleMentalhygiene/updateJoy', { joy: j.joy, id: j.nodeId });
    },

    completed() {
      if(this.cardCount){
        this.$emit('completed')
        this.showingTitle = false
        this.editing = false
        if(this.joys[this.cardCount-1].joy === ''){
            this.$store.dispatch('moduleMentalhygiene/deleteJoy', this.joys[this.cardCount-1].nodeId);
        }
        this.trackPhotoDownloads();
      }
    },

    trackPhotoDownloads() {
      // increment unsplash download counter
      const clientId = "vbMmjgnUm9nIn8RpcjHAQ1fuyzPoAnVyiGwNTIyMQaU";
      const headers = { 'Authorization': `Client-ID ${clientId}` };
      this.joys
        .filter(j => j.image)
        .forEach(j => {
          const url = j.image.links.download_location;
          fetch(url, { headers });
        });
    },

    onEditing() {
      this.editing = true;
    },

    onPosted() {
      this.updateJoy();
      this.suggestPhotos();
      this.editing = false;
    },

    suggestPhotos() {
      const j = this.joys[this.activeCardIndex];
      const q = j.joy;
      photoSearch(q)
        .then(response => {
          this.images = response.data.images;
          if (this.images.length) {
            const image = this.images[0];
            this.setJoyImage(j, image);
          }
        })
        .catch(err => console.error);
    },

    setJoyImage(joy, image) {
      return this.$store.dispatch('moduleMentalhygiene/updateJoy',
        { joy: joy.joy,
          id: joy.nodeId,
          image: image
        });
    },

    onShufflePhoto() {
      const j = this.joys[this.activeCardIndex];
      if (this.images.length) {
        let idx = this.joyPhotoIndex + 1;
        if (idx >= this.images.length) { idx = 0 };
        this.joyPhotoIndex = idx;
        const image = this.images[idx];
        this.setJoyImage(j, image);
      }
    },

    shuffle(card) {
      this.activeCardIndex = card;
    },

    async addJoy() {
      if (this.canAdd) {
        await this.$store.dispatch('moduleMentalhygiene/createJoy', { joy: '' });
        this.activeCardIndex = this.joys.length - 1;
      }
    },
  },
};
</script>

<style>
body {
  background: #6632ab;
}
</style>

<style scoped>
.JoyCard {
  position: absolute;
}
.titleContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  height: 4em;
  padding-left: 1em;
  padding-right: 1em;
  top: 2em;
}
.categoryTitle {
  color: rgba(240, 248, 255, 0.418);
  font-size: 100%;
}
.title {
  color: rgba(255, 255, 255, 0.918);
  font-size: 210%;
  font-weight: 600;
}
.cards {
  width: 18em;
  height: 20.7em;
}

.JoyContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 1em;
  left: 0;
  bottom: 0;
  right: 0;
}

@keyframes activate_card {
  0% {
    transform: scale(1.05);
  }
  50% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

.card-container {
  position: relative;
}

.card-container.active {
  z-index: 2000 !important;
}

@keyframes slide {
  0% {
    transform: rotate(0deg) translateX(-100vw);
  }
}

.slide-enter-active {
  animation: slide 0.5s;
  animation-timing-function: ease-in-out;
}

.JoyCard {
  position: absolute;
}

.JoyCard.active {
  animation-name: activate_card;
  animation-duration: 0.3s;
}

.visible {
  visibility: visible;
}
.buttons {
  /* border: 1px solid red; */
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  bottom: 1em;
  padding-left: 1em;
  padding-right: 2em;
  visibility: visible;
}
.buttons.disabled {
  visibility: hidden;
}
.prNextButtonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 10em;
}
.doneButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5em;
  width: 5em;
  height: 5em;
  opacity: 0.4;
  background-color: rgb(100, 203, 225);
  color: white;
  font-size: 12px;
}
.doneButton:hover {
  cursor: pointer;
}
.doneButton.active {
  opacity: 1;
}
.previousButton:hover {
  cursor: pointer;
}
.nextButton {
  opacity: 1;
}
.fade {
  opacity: 0.5;
  cursor: default !important;
}
.nextButton:hover {
  cursor: pointer;
}
.postButton {
  position: absolute;
  bottom: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(255, 255, 255);
  background: rgb(100, 203, 225);
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  border-radius: 3px;
  width: 7em;
  height: 2.5em;
  margin-bottom: 0.5em;
  padding-right: 0.5em;
  padding-left: 0.5em;
  z-index: 100;
}
.postButton:hover {
  cursor: pointer;
}
.stepsCountText {
  color: rgba(255, 255, 255, 0.719);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.add {
  opacity: 1;
}
.add.fade {
  opacity: 0.5;
}
.spacer_main {
  height: 3em;
}
@media (max-width: 620px) {
.titleContainer {
    height: 3.4em;
    padding-left: 1em;
    padding-right: 1em;
    top: 0em;
}
.categoryTitle {
    font-size: 85%;
}
.title {
    font-size: 160%;
}
}
</style>
