<template>
  <div class="TransitioningScreenContainer">
    <div
      ref="imageContainer"
      class="animContainer" />
  </div>
</template>

<script>
import animationData from '../../assets/dayplan_trans_screen.json'
import lottie from "lottie-web";

export default {
    props: {
        currentStep: Number,
        skipAnim: Boolean,
        playAnim: Boolean
    },
    data(){
        return {
            animationState: 'initial',
            timer:null,
        }
    },
    computed:{
        
    },
    watch: {
        playAnim(v){
            if(v){
                this.animationState = 'intro'
                this.timer? clearTimeout(this.timer) : false
                this.timer = setTimeout(() => {
                    this.$emit('tomorrowsTitle') 
                }, 2000);
            } else {
                this.animationState = 'i'
            }
        },
        animationState(v){
            if(v==='initial'){
                this.animate(0,1)
            } else if (v==='intro'){
                this.animate(0,200)
            } 
        },
    },
    methods: {
        animate(a,b){
        this.anim.show()
            this.anim.playSegments([a, b], true);
            this.anim.onComplete = ()  => {
                this.anim.show()
            }
        },
    },
    mounted(){
        this.anim = lottie.loadAnimation({
        container: this.$refs.imageContainer,
        renderer: "canvas",
        loop: false,
        autoplay: false,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid meet'
        },
        animationData,
        });
        this.animationState = 'intro'
        setTimeout(() => {
            this.$emit('tomorrowsTitle')
        }, 2000);
    },
    beforeDestroy() {
      this.anim.destroy();
    }

}
</script>

<style scoped>
    .TransitioningScreenContainer {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 70%;
        max-width: 100%;
    }
    .animContainer {
        /* max-width: 70em; */
        height: 70%;
        width: 50em;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    /* .subTextContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 8em;
        width: 30em;
        margin-top: 5em;
    } */
    /* .subText1 {
        font-weight: 500;
        color: rgba(255, 255, 255, 0.822);
        font-size: 160%;
        width: 100%;
        text-align: center;
        padding-left: 1em;
        padding-right: 1em; 
    }
    .subText2 {
        font-weight: 500;
        color: rgba(255, 255, 255, 0.822);
        font-size: 100%;
        width: 100%;
        text-align: center;
        padding-top: 0.2em;
    } */
</style>