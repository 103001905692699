import axios from 'axios';

function photoSearch(q) {
  if (q.length < 1)
    return Promise.resolve();
  return axios.get(
    'https://share.memolife.com/api/photos/search',
    // 'http://localhost:8001/api/photos/search',
    { params: { q } });
}

export {
  photoSearch,
}
