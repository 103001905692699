<template>
  <div class="MainContainer">
    <div class="titleContainer" :class="{ plan: step === 2 }">
      <div class="title" :class="{ plan: step === 2 }">
        {{ $t(title) + name }} 
      </div>
      <div class="subTitle" :class="{ plan: step === 2 }">
        {{ $t(subTitle) }}
      </div>
    </div>
    <div class="bgContainer">
      <div class="bg">
        <div @click="$emit('completed')" class="closeButton">X</div>
      </div>
    </div>
    <AnimationCanvas v-if="step === 1" :animation-state="'set_plan'" class="animationCanvas" />
    <Sliders
      v-if="step === 2"
      class="sliders"
      :current-step="4"
      :skip-anim="true"
      :finished-frogs="0"
      :finished-tasks="0"
      :planned-tasks="plannedFrogs"
      :planned-frogs="plannedTasks"
      @setPlannedFrogs="setPlannedFrogs"
      @setPlannedTasks="setPlannedTasks"/>
    <div class="buttons">
      <div class="startButton" @click="startButton()">{{ $t(buttonTitle) }}</div>
    </div>
  </div>
</template>

<translations>
  categoryTitleProductivity: 'PRODUCTIVITY'
  categoryTitleProductivity_no: 'PRODUKTIVITET'

  hello: 'Hello '
  hello_no: 'Hei '

  subTitle1: "It seems like you haven't planned your day yet. Let's set up the dominoes and turn your day into the fairytale you deserve!"
  subTitle1_no: "Det ser ut som du ikke har planlagt dagen enda. La oss sette opp dominoene og rigge opp dagen for det eventyret livet ditt fortjener."
  subTitle2: 'Plan your tasks and frogs for today'
  subTitle2_no: "Planlegg oppgavene og froskene for i dag!"

  startButton: 'Start'

  doneButton: 'Done'
  doneButton_no: 'Ferdig'
</translations>

<script>
import AnimationCanvas from '../components/AnimationCanvas.vue';
import Sliders from '../components/planCardComponents/Sliders.vue';
import moment from 'moment';

export default {
  components: {
    AnimationCanvas,
    Sliders,
  },
  computed: {
    profile() {
      return this.$store.getters['moduleAuth/profile'];
    },

    name() {
      return this.step===1? this.profile.first_name + ',' : '';
    }
  },
  data() {
    return {
      step: 1,
      title: 'hello',
      subTitle: 'subTitle1',
      buttonTitle: 'startButton',
      plannedFrogs: 0,
      plannedTasks: 0,
    };
  },

  mounted(){
  },

  methods: {
    startButton() {
      const yesterday = moment().subtract(1, 'days').format('YYYY-MM-D');
      const dayplan = this.$store.getters['moduleMentalhygiene/dayplanYesterday'];
      if (this.step === 1) {
        this.step = 2;
        this.buttonTitle = 'doneButton';
        this.title = 'categoryTitleProductivity';
        this.subTitle = 'subTitle2';
      } else {
        this.$emit('completed');
        if(!dayplan){
          this.$store.dispatch('moduleMentalhygiene/createDayplan', {
            finishedTasks: 0,
            finishedFrogs: 0,
            productivity: 1,
            purpose: 1,
            plannedFrogs: this.plannedFrogs,
            plannedTasks: this.plannedTasks,
            date: yesterday,
          });
        } else {
          this.$store.dispatch('moduleMentalhygiene/updateDayplan', {
            nodeId: dayplan.nodeId,
            finishedTasks: dayplan.finishedFrogs,
            finishedFrogs: dayplan.finishedTasks,
            productivity: dayplan.productivity,
            purpose: dayplan.purpose,
            plannedFrogs: this.plannedFrogs,
            plannedTasks: this.plannedTasks,
            date: yesterday,
          });
        }
      }
    },
    setPlannedFrogs(f) {
      this.plannedFrogs = parseInt(f);
    },
    setPlannedTasks(t) {
      this.plannedTasks = parseInt(t);
    },
  },
};
</script>

<style scoped>
.MainContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: white;
}
.bgContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #2a165a;
}
.bg {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  padding-top: 1.3em;
  padding-right: 1.5em;
  width: 70%;
  height: 100%;
  background-color: #2d1860;
}
.closeButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 21px;
  font-weight: 200;
  transform: scaleX(1.3);
  width: 1.2em;
  height: 1.2em;
  color: rgba(255, 255, 255, 0.596);
  z-index: 1000;
}
.closeButton:hover {
  cursor: pointer;
  color: rgb(230, 100, 100);
}
.titleContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  height: 4em;
  padding-left: 1em;
  padding-right: 1em;
  top: 3em;
  height: 7em;
  z-index: 100;
}
.sliders {
  z-index: 100;
}
.titleContainer.plan {
  height: 4.3em;
}
.title {
  flex:1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.918);
  font-size: 210%;
  font-weight: 600;
  opacity: 0.9;
  line-height: 2em;
}
.title.plan {
  font-size: 100%;
  font-weight: 400;
  opacity: 0.5;
}
.subTitle {
  flex:1;
  color: rgb(240, 248, 255);
  font-size: 100%;
  width: 20em;
  opacity: 0.4;
}
.subTitle.plan {
  font-size: 210%;
  font-weight: 600;
  opacity: 0.9;
}
.buttons {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  bottom: 2em;
  padding-left: 1em;
  padding-right: 2em;
  z-index: 1000;
}
.startButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(255, 255, 255);
  background: rgb(100, 203, 225);
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  border-radius: 3px;
  width: 9em;
  height: 2.5em;
  margin-bottom: 0.5em;
  padding-right: 0.5em;
  padding-left: 0.5em;
}
.startButton:hover {
  cursor: pointer;
}
@media (max-width: 620px) {
  .subTitle.plan {
    font-size: 150%;
    width: 15em;
  }
  .bg {
    width: 100%;
  }
  .title.plan {
    font-size: 90%;
  }
}
</style>
