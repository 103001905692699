<template>
  <div class="WeeklyProgressStep">

    <div ref="riveCont" class="riveContainer">
      <canvas id="canvas" width="300px" height="300px"></canvas>
    </div>
    <div class="WeekContainer">
      <div v-for="([dayOfWeek, isDone], index) in week" :key="index">
        <div class="dayContainer">
          {{ dayOfWeek }}
          <!-- <div :class="{ filled: isDone }" class="dayCircle"></div> -->
          <Icons :class="{ filled: isDone }" class="dayCircle" :step="step+1" />
        </div>
      </div>
    </div>
    <div class="iconsCountText">{{ 5-getDaysDone }} {{ $t('moreForYour') }} {{ $t(badgeNames[step]) }}!</div>
  </div>
</template>

<translations>
  moreForYour: 'more for your'
  moreForYour_no: 'til for'
  happinessMedallion: 'Happiness Medallion'
  happinessMedallion_no: 'lykkemedaljongen'
  powerCrown: 'Power Crown'
  powerCrown_no: 'kraftkronen'
  freedomFocus: 'Freedom Focus'
  freedomFocus_no: 'frihetsfokus'
  productivityDiamond: 'Productivity Diamond'
  productivityDiamond_no: 'produktivitetsdiamanten'
  wisdomOwl: 'Wisdom Owl'
  wisdomOwl_no: 'visdomsuglen'

  doneTitleYoure: "You're a"
  doneTitleYoure_no: 'Du er en'
  doneTitleCongrats: 'Congrats,'
  doneTitleCongrats_no: 'Grattis,'

  doneTitle: ' you finished your frogs!'
  doneTitle_no: ' du er ferdig med å kysse froskene!'

  doneAllTitle: ' you finished your mental hygiene!'
  doneAllTitle_no: ' du er ferdig med dagens mentalhygiene!'
</translations>

<script>
import moment from 'moment';
const rive = require("rive-js");
import RiveFile from '../assets/rive/weekly_progress.riv'
import Icons from '../components/TopBarAnimations.vue';

export default {
  components: {
    Icons
  },
  props: {
    step: Number,
  },
  data() {
    return {
      r: null,
      inputs: null,
      badgeNames: ['happinessMedallion','powerCrown','freedomFocus','productivityDiamond','wisdomOwl']
    };
  },
  computed: {
    week() {
      return [
        ['m', this.monday],
        ['t', this.tuesday],
        ['w', this.wednesday],
        ['t', this.thursday],
        ['f', this.friday],
        ['s', this.saturday],
        ['s', this.sunday],
      ];
    },
    allJoys() {
      return this.$store.getters['moduleMentalhygiene/allJoys'];
    },
    allFrogs() {
      return this.$store.getters['moduleMentalhygiene/allFrogs'];
    },
    allFocusChecks() {
      return this.$store.getters['moduleMentalhygiene/allFocusChecks'];
    },
    allDayplans() {
      return this.$store.getters['moduleMentalhygiene/dayplans'];
    },
    allDiaries() {
      return this.$store.getters['moduleMentalhygiene/diaries'];
    },
    monday() {
      const day = moment().startOf('isoWeek');
      return this.isDayFinished(day)
    },
    tuesday() {
      const day = moment().startOf('isoWeek').add(1, 'days');
      return this.isDayFinished(day)
    },
    wednesday() {
      const day = moment().startOf('isoWeek').add(2, 'days');
      return this.isDayFinished(day)
    },
    thursday() {
      const day = moment().startOf('isoWeek').add(3, 'days');
      return this.isDayFinished(day)
    },
    friday() {
      const day = moment().startOf('isoWeek').add(4, 'days');
      return this.isDayFinished(day)
    },
    saturday() {
      const day = moment().startOf('isoWeek').add(5, 'days');
      return this.isDayFinished(day)
    },
    sunday() {
      const day = moment().startOf('isoWeek').add(6, 'days');
      return this.isDayFinished(day)
    },
    getDaysDone(){
     var daysDone = 0
       for(let i = 0; i < this.week.length; i++){
         if(this.week[i][1]){
           daysDone = daysDone + 1
         }
       }
     return daysDone
    },
    weeklyBadgeDone() {
      return this.getDaysDone>=5;
    },
    allDone() {
      return this.$store.getters['moduleMentalhygiene/doneToday'];
    },
    
  },
  mounted() {
    this.r = new rive.Rive({
      src: new Request(RiveFile),
      canvas: document.getElementById('canvas'),
      stateMachines: 'State Machine 1',
      autoplay: true,
      layout: new rive.Layout({fit: 'fill', alignment: 'center'}),
      onload: () => {
        this.inputs = this.r.stateMachineInputs('State Machine 1');
        this.inputs[0].value = this.step + 1;
        this.inputs[1].value = this.getDaysDone<=5? this.getDaysDone : 5;
      },
    });
    if(this.weeklyBadgeDone) {
      setTimeout(() => {
        this.$emit('weeklyBadgeDone') 
      }, 1200);
    }
  },

  beforeDestroy(){
    this.r.stop();
    this.r.unsubscribeAll();
    this.r = null;
  },

  methods: {
    isDayFinished(day){
      if(this.step===0){
        return this.isFinishedThisDay(this.allJoys, day);
      }else if(this.step===1){
        return this.isFrogsFinishedThisDay(day);
      }else if(this.step===2){
        return this.isFinishedThisDay(this.allFocusChecks, day);
      }else if(this.step===3){
        return this.isFinishedThisDay(this.allDayplans, day);
      }else if(this.step===4){
        return this.isFinishedThisDay(this.allDiaries, day);
      }
    },

    isFinishedThisDay(what, day) {
      return this.onDay(what, day).length > 0;
    },

    isFrogsFinishedThisDay(day) {
      return this.onDay(this.allDayplans, day)
        .reduce((acc, curr) => {
          acc = acc + curr.finishedFrogs;
          return acc;
        }, 0) > 0;
    },

    onDay(content, d) {
      return content.filter(j => {
        const c = moment(j.createdAt);
        return c.isSame(d, 'day');
      });
    },

  },
};
</script>

<style scoped>
.WeeklyProgressStep {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30em;
  height: 24em;
  color: white;
}
.riveContainer {
  margin-bottom: 1em;
  /* border: 2px solid rgba(255, 255, 255, 0.541); */
}
.WeekContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 30em;
  font-size: 90%;
}
.dayContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 3em;
  height: 4.7em;
}
.dayCircle {
  /* background-color: rgba(63, 157, 194, 0.404); */
  /* border-radius: 10em; */
  opacity: 0.3;
}
.dayCircle.filled {
  opacity: 1;
}
.iconsCountText {
  bottom: -4em;
  margin-top: 1.7em;
  opacity: 0.3;
}
#canvas {
  height: 300px;
  width: 300px;
}
@media (max-width: 620px) {
      .WeeklyProgressStep {
        margin-bottom: 3em;
      }
      .WeekContainer {
        padding-left: 1.4em;
        padding-right: 1.4em;
      }
      .riveContainer {
        margin-bottom: 0em;
        /* border: 2px solid rgba(255, 255, 255, 0.541); */
      }
}
@media (max-height: 650px) {
      #canvas {
        height: 220px;
        width: 220px;
      }
}
</style>
