<template>
  <div class="InputMainContainer">
    <div class="inputContainer">
      <div class="inputFieldAndButton">
        <textarea 
          v-if="currentStep===0"
          ref="inputField1"
          v-model="text1"
          :class="{invalid:this.emptyText}"
          class="textField"
          oninput="this.value = this.value.replace(/\n/g,'')"
          maxlength="100"
          spellcheck="false"
          :placeholder="$t(textareaPlaceholderText)"
          @keyup.enter="post()"
          @focus="editingText=true"
          @blur="editingText=false"
          rows="1"
          cols="30" />
        <textarea 
          v-if="currentStep===1"
          ref="inputField2"
          v-model="text2"
          :class="{invalid:this.emptyText}"
          class="textField"
          oninput="this.value = this.value.replace(/\n/g,'')"
          maxlength="100"
          spellcheck="false"
          :placeholder="$t(textareaPlaceholderText)"
          @keyup.enter="post()"
          @focus="editingText=true"
          @blur="editingText=false"
          rows="1"
          cols="30" />
        <textarea 
          v-if="currentStep===2"
          ref="inputField3"
          v-model="text3"
          :class="{invalid:this.emptyText}"
          class="textField"
          oninput="this.value = this.value.replace(/\n/g,'')"
          maxlength="100"
          spellcheck="false"
          :placeholder="$t(textareaPlaceholderText)"
          @keyup.enter="post()"
          @focus="editingText=true"
          @blur="editingText=false"
          rows="1"
          cols="30" />
        <textarea 
          v-if="currentStep===3"
          ref="inputField4"
          v-model="text4"
          :class="{invalid:this.emptyText}"
          class="textField"
          oninput="this.value = this.value.replace(/\n/g,'')"
          maxlength="100"
          spellcheck="false"
          :placeholder="$t(textareaPlaceholderText)"
          @keyup.enter="post()"
          @focus="editingText=true"
          @blur="editingText=false"
          rows="1"
          cols="30" />
        <!-- <div :class="{active:editingText && !emptyText}" @click="post()" class="addButton">
            Add
        </div> -->
      </div>
      <!-- <div class="line"></div> -->
      <!-- <div class="smileysContainer">
        <div class="smiley"></div>
        <div class="smiley"></div>
        <div class="smiley"></div>
        <div class="smiley"></div>
      </div> -->
      <div class="diaryProgressBarWrapper">
        <div
          :class="{active:currentStep>=0}"
          class="diaryProgressBar" />
        <div
          :class="{active:currentStep>=1}"
          class="diaryProgressBar" />
        <div
          :class="{active:currentStep>=2}"
          class="diaryProgressBar" />
        <div
          :class="{active:currentStep===3}"
          class="diaryProgressBar" />
      </div>
    </div>
  </div>
</template>

<translations>
  textareaPlaceholder1: 'Brain hug: list one thing you did well'
  textareaPlaceholder1_no: 'Hjerneklem: nevn noe du har gjort bra'
  textareaPlaceholder2: 'Truth talk: list one thing you can improve'
  textareaPlaceholder2_no: 'Sannhetsblikk: nevn en ting du kan forbedre'
  textareaPlaceholder3: 'Brain hug: list another thing you did well'
  textareaPlaceholder3_no: 'Hjerneklem: noe annet du har gjort bra'
  textareaPlaceholder4: 'Freestyle: which other gifts has today given you? (optional)'
  textareaPlaceholder4_no: 'Freestyle: hvilke andre gaver har dagen gitt deg? (valgfritt)'
</translations>

<script>
export default {
  props: {
    currentStep: Number
  },
  data(){
    return {
      inputTitle: 'List one thing you did well', 
      text1: '',
      text2: '',
      text3: '',
      text4: '',
      editingText: false,
      placeholderText: 'Type your answer here',
      emptyText: false,
      textWasInvalid: false,
      textareaPlaceholderText: 'textareaPlaceholder1'
    }
  },
  computed: {
      diaryToday(){
        const diary = this.$store.getters['moduleMentalhygiene/todaysDiary'].slice()
        return diary
      }
  },
  watch: {
    editingText(v){
      if(v){
        this.textWasInvalid = true
      } else if(!v && this.currentStep===0 && !this.text1 && this.textWasInvalid){
        this.emptyText = true
        this.$emit('disableButtons')
        this.$refs.inputField1.focus();
      } else if(!v && this.currentStep===1 && !this.text2 && this.textWasInvalid){
        this.emptyText = true
        this.$emit('disableButtons')
        this.$refs.inputField2.focus();
      } else if(!v && this.currentStep===2 && !this.text3 && this.textWasInvalid){
        this.emptyText = true
        this.$emit('disableButtons')
        this.$refs.inputField3.focus();
      } else if(!v && this.currentStep===3 && !this.text4 && this.textWasInvalid){
        // this.emptyText = true
        // this.$emit('disableButtons')
        // this.$refs.inputField4.focus();
      }
    },
    text1(v){
      if(v){
        if(this.emptyText){
          this.emptyText = false
          this.$emit('enableButtons')
        } 
      } else {
        if(this.textWasInvalid) {
          this.emptyText = true
          this.$emit('disableButtons')
          this.$refs.inputField1.focus();
        }
      }
    },
    text2(v){
      if(v){
        if(this.emptyText){
          this.emptyText = false
          this.$emit('enableButtons')
        } 
      } else {
        if(this.textWasInvalid) {
          this.emptyText = true
          this.$emit('disableButtons')
          this.$refs.inputField2.focus();
        }
      }
    },
    text3(v){
      if(v){
        if(this.emptyText){
          this.emptyText = false
          this.$emit('enableButtons')
        } 
      } else {
        if(this.textWasInvalid) {
          this.emptyText = true
          this.$emit('disableButtons')
          this.$refs.inputField3.focus();
        }
      }
    },
    text4(v){
      if(v){
        if(this.emptyText){
          // this.emptyText = false
          // this.$emit('enableButtons')
        } 
      } else {
        if(this.textWasInvalid) {
          // this.emptyText = true
          // this.$emit('disableButtons')
          // this.$refs.inputField1.focus();
        }
      }
    },
    currentStep(n, p){
      if(n===0){
        this.textareaPlaceholderText = 'textareaPlaceholder1'
        this.focusText(1);
      } else if(n===1){
        this.textareaPlaceholderText = 'textareaPlaceholder2'
        this.focusText(2);
      } else if(n===2){
        this.textareaPlaceholderText = 'textareaPlaceholder3'
        this.focusText(3);
      } else if(n===3){
        this.textareaPlaceholderText = 'textareaPlaceholder4'
        this.focusText(4);
      }
      if(p==0){
        this.addDiaryItem(0,'POSITIVE',this.text1)
      } else if(p===1){
        this.addDiaryItem(1,'NEGATIVE',this.text2)
      } else if(p===2){
        this.addDiaryItem(2,'POSITIVE',this.text3)
      } else if(p===3){
        this.addDiaryItem(3,'NEUTRAL',this.text4)
      }
    }
  },
  methods: {
    autoheight(x) {
      x.style.height = "5px";
      x.style.height = (15+x.scrollHeight)+"px";
    },
    addDiaryItem(arr,conn, text){
      if(this.diaryToday[arr] && this.diaryToday[arr].item !== text) {
        this.$store.dispatch('moduleMentalhygiene/updateDiary', {item: text, id: this.diaryToday[arr].nodeId}) 
      } else if(!this.diaryToday[arr]){
        this.$store.dispatch('moduleMentalhygiene/createDiary', {connotation: conn, item: text})
      }
    },
    deleteDiary(){
      this.$store.dispatch('moduleMentalhygiene/deleteDiary')
    },
    post(){
       if(this.currentStep===0){
         if(this.text1){
          //  this.addDiaryItem(0,'POSITIVE',this.text1)
          //  this.deleteDiary()
           this.$refs.inputField1.blur();
           this.$emit('post')
           this.textWasInvalid = false
         } else {
           this.emptyText = true
           this.$emit('disableButtons')
           this.textWasInvalid = true
           this.$refs.inputField1.focus();
         }
       } else if(this.currentStep===1){
         if(this.text2){
          // this.addDiaryItem(1,'NEGATIVE',this.text2)
          this.$refs.inputField2.blur();
          this.$emit('post')
          this.textWasInvalid = false
         }else {
           this.emptyText = true
           this.$emit('disableButtons')
          this.textWasInvalid = true
          this.$refs.inputField2.focus();
         }
       } else if(this.currentStep===2){
         if(this.text3){
          // this.addDiaryItem(2,'POSITIVE',this.text3)
          this.$refs.inputField3.blur();
          this.$emit('post')
          this.textWasInvalid = false
          this.$emit('enableButtons')
         }else {
           this.emptyText = true
           this.$emit('disableButtons')
          this.textWasInvalid = true
          this.$refs.inputField3.focus();
         }
       } else if(this.currentStep===3){
         if(this.text4){
          this.addDiaryItem(3,'NEUTRAL',this.text4)
          this.$refs.inputField4.blur();
          this.$emit('post')
          this.textWasInvalid = false
          this.$emit('enableButtons')
         }else {
          //  this.emptyText = true
          //  this.$emit('disableButtons')
          // this.textWasInvalid = true
          // this.$refs.inputField4.focus();
         }
       }
    },
    focusText(v){
      setTimeout(() => {
        if(v===1){
          if(!this.text1){
             this.$refs.inputField1.focus();
          }
        } else if(v===2){
          if(!this.text2){
            this.$refs.inputField2.focus();
          }
        } else if(v===3){
            if(!this.text3){
            this.$refs.inputField3.focus();
          }
        } else if(v===4){
            if(!this.text4){
            this.$refs.inputField4.focus();
          }
        }
      }, 50);
    }
  },
  mounted(){
    this.diaryToday[0]? this.text1 = this.diaryToday[0].item : this.text1 = ''
    this.diaryToday[1]? this.text2 = this.diaryToday[1].item : this.text1 = ''
    this.diaryToday[2]? this.text3 = this.diaryToday[2].item : this.text1 = ''
    this.diaryToday[3]? this.text4 = this.diaryToday[3].item : this.text1 = ''
    if(this.diaryToday[0]){
      this.text1 = this.diaryToday[0].item
      this.emptyText = false
      this.$emit('enableButtons')
    } else {
      this.text = '';
    }

  }
}
</script>

<style scoped>
.InputMainContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items:initial;
  width: 20em;
  height: 150px;
  padding-top: 4em;
}
.textField {
  margin-right: 0.2em;
}
.textField.invalid {
  border: 1px solid rgba(175, 44, 121, 0.842);
}
.inputTextContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items:initial;
}
.inputTitle {
  color: rgba(255, 255, 255, 0.733);
  font-size: 100%;
}
.addButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-color: rgb(100, 203, 225);
  width: 5.5em;
  height: 3em;
  border-radius: 0.3em;
  font-size: 13px;
  padding: 1em;
  color: white;
  opacity: 0;
}
.addButton.active {
  opacity: 1;
}
.addButton:hover{
  cursor: pointer;
}
.inputFieldAndButton {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 3.5em;
}
.line{
  height: 1px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.377);
  margin-bottom: 1em;
  margin-top: 0.5em;
}
.smileysContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items:initial;
  width: 40%;
}
.smiley {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: rgb(240, 210, 41);
}
textarea:focus,
select:focus {
  outline: none;
}
textarea {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 5.5em;
  padding: 14px 5px;
  margin: 0px;
  background: none;
  border: none;
  color: rgb(255, 255, 255);
  font-size: 10px;
  resize: none; 
  overflow:hidden;
}
textarea.postedText {
  pointer-events: none; 
}
textarea::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.322);
  font-weight: normal;
  font-size: 10px;
}
.diaryProgressBarWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 2em;
}
.diaryProgressBar {
  flex: 1;
  height: 0.12em;
  background-color: rgba(79, 87, 109, 0.589);
  margin: 0.2em;
}
.diaryProgressBar.active {
  background-color: rgba(187, 236, 255, 0.671);
}
@media screen and (min-width: 480px) {
    .InputMainContainer {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items:initial;
      width: 30em;
      height: 150px;
      padding-top: 4em;
    }
    .smileysContainer {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items:initial;
      width: 30%;
    }
    textarea::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.322);
      font-weight: normal;
      font-size: 15px;
    }
    textarea {
      font-size: 15px;
      height: 4em;
    }
}
</style>