<template>
  <div class="PopoutContainer">
    <div class="popoutWindow">
      <div
        @click="closeButton()"
        class="closeButton">
        {{ $t('close') }}
      </div>
      <div class="titleTextContainer">
        <div class="title">
          {{ $t(stepTitle) }}
        </div>
        <div class="textBox">
          <b class="text1"> {{ $t(text1) }} </b> <br> <br> {{ $t(text2) }} <br> <br> {{ $t(text3) }}
        </div>
      </div>
      <div
        @click="openLifeskill()"
        class="clickableArea" />
      <div class="bottomContainer">
        <div class="line" />
        <div class="learnMoreContainer">
          <div class="image">
            <img
              class="imageAsset"
              v-show="currentStep===0"
              src="../assets/joyLearnMoreImage.jpg">
            <img
              class="imageAsset"
              v-show="currentStep===1"
              src="../assets/frogLearnMoreImage.jpg">
            <img
              class="imageAsset"
              v-show="currentStep===2"
              src="../assets/focusLearnMoreImage.jpg">
            <img
              class="imageAsset"
              v-show="currentStep===3"
              src="../assets/planLearnMoreImage.jpg">
            <img
              class="imageAsset"
              v-show="currentStep===4"
              src="../assets/diaryLearnMoreImage.jpg">
          </div>
          <div class="learnMoreTitleContainer">
            <div class="stepNum">
              Step {{ currentStep+1 }}
            </div>
            <div class="stepTitle">
              {{ $t(stepTitle) }}
            </div>
          </div>
        </div>
        <div class="line" />
      </div>
      <div class="bottomLink">
        {{ $t('linkText') }}
      </div>
    </div>
  </div>
</template>

<translations>
    stepTitleJoy: 'Daily Joy'
    stepTitleJoy_no: 'Dagens gleder'
    stepTitleFrog: 'Kiss the Frog'
    stepTitleFrog_no: 'Kyss froskene'
    stepTitleFocus: 'Focus Check'
    stepTitleFocus_no: 'Fokussjekker'
    stepTitlePlan: 'Daily Plan'
    stepTitlePlan_no: 'Dagsplanen'
    stepTitleDiary: 'Learning Diary'
    stepTitleDiary_no: 'Læringsdagboken'

    linkText: 'Mental hygiene for the home office'
    linkText_no: 'Mentalhygiene på hjemmekontoret'

    text1Joy: 'Our brain doesn’t know the difference between imagination and reality!'
    text1Joy_no: 'Hjernen vår vet ikke forskjellen på fantasi og virkelighet!'
    text2Joy: 'You see, it stores both memories and our thoughts about the future the same way. Why does this matter? It matters, because it means that «fake it til you make it» actually works. Not only can we trick our brains to become motivated and happier, but studies now show that when we do this the results in productivity and sales increases with more than 30%!'
    text2Joy_no: 'Den lagrer nemlig både minner og tanker om fremtiden på nøyaktig samme måte. Ok, greit nok, men hvorfor er nå det viktig? Det er viktig fordi det betyr at « fake it till you make it» faktisk funker. Ikke bare kan vi lure hjernen vår til å bli mer motiverte og lykkeligere, men studier viser at når vi gjør dette, så øker både produktiviteten og salgsresultatene i bedrifter med mer enn 30%!'
    text3Joy: 'So, starting your day with joys not only makes you feel good, but it’s also good for business.'
    text3Joy_no: 'Så, det å starte dagen med (imaginære) gleder er ikke bare godt for deg, men der også « good for business».'
    text1Frog: 'Our brain’s most important task is to save energy. It does so by avoiding pain and moving towards pleasure.'
    text1Frog_no: 'Hjernens viktigste oppgave er å spare energi. Det gjør den ved å unngå smerte og bevege seg mot glede.'
    text2Frog: 'But, ironically, this «brain program» ultimately leads us to spending more energy, as we keep on procrastinating the important tasks we associate with pain.'
    text2Frog_no: 'Men ironisk nok fører dette «hjerneprogrammet» oss til å bruke mer energi, idet vi daglig fortsetter med å utsette oppgaver som vi forbinder med smerte.'
    text3Frog: '«Kissing the frog» tricks your brain into having fun, while doing the important things you somehow didn’t feel like doing. What a feeling it is to finally finish something you’ve avoided for a long time! Suddenly your day can turn into the prince or princess adventure we all want life to be.'
    text3Frog_no: 'Å «kysse froskene» lurer hjernen din til å ha det gøy, mens du faktisk fullfører tingene du egentlig ikke føler for å gjøre. For er det ikke en fantastisk følelse å endelig fullføre noe du har unngått i lang tid? Plutselig kan hverdagen forvandle seg til det prins eller prinsesseeventyret vi alle vil at livet skal være.'
    text1Focus: 'Our brains are wired to be on the alert, to react to distractions in our surroundings - for a good reason!'
    text1Focus_no: 'Hjernen er rigget for å være på vakt, til å reagere på forstyrrelser i omgivelsene våre for en god grunn!'
    text2Focus: 'At the same time, the primitive brain parts responsible for our survival were programmed to escape from lions and tigers – not from incoming emails, disturbing colleagues or pending tasks you don’t feel like doing. It’s no wonder research shows that we spend almost half of our days focusing on something different than what we had planned.'
    text2Focus_no: 'Samtidig, så er de primitive hjernedelene som er ansvarlige for vår overlevelse programmert til å flykte fra løver og tigre ikke fra innkommende eposter, forstyrrende kolleger eller ventende oppgaver du egentlig ikke har lyst til å ta tak i. Derfor er det kanskje ikke så overraskende med studiene som viser at vi bruker nærmest halvparten av hverdagen vår til å fokusere på noe annerledes enn det vi hadde planlagt.'
    text3Focus: 'Enter focus checks – the disturbances that reprogram your brain to be more focused, kinder to your mind and more balanced in your body.'
    text3Focus_no: 'Inn på banen med fokussjekker forstyrrelsene som omprogrammerer hjernen til å bli mer fokusert, snillere med sinnet og balansert i kroppen.'
    text1Plan: 'In today’s world our brain is often faced with information overload and decision fatigue.'
    text1Plan_no: 'I dagens verden blir hjernen bombardert med inntrykk, som fort fører til « information overload » og « decision fatigue ».'
    text2Plan: 'You see, all information thrown at us in everyday life still needs to be security checked by our more primitive brain parts. Thousands of years ago they ‘applied’ for that job … and they still got it. The result is that – quite often - we’re distracted or mentally discouraged from finishing our day plans. The negative implications this has for both our personal and business life goes without saying.'
    text2Plan_no: 'Det har seg nemlig slik at all informasjon som kastes mot oss først må sikkerhetsklareres av våre primitive hjernedeler. For tusenvis av år siden søkte disse på den jobben … og de har beholdt den siden. Resultatet er at vi ofte blir forstyrret eller demotiverte fra å fullføre dagsplanen. De negative effektene dette har å si på både jobb og hjemmebane sier seg selv.'
    text3Plan: 'Enter the day plan – a low threshold, realistic and exciting method to help you plan your work and work your plan. Your brain will love you for it, and you will love your days!'
    text3Plan_no: 'Inn på banen med dagsplanen en lavterskel, realistisk og spennende metode for å «plan your work » og « work your plan». Hjernen din vil elske deg, og du vil elske hverdagen!'
    text1Diary: 'Your brain doesn’t really know the difference between something of importance or irrelevance.'
    text1Diary_no: 'Hjernen din vet ikke forskjell på noe som er viktig og noe som er uviktig.'
    text2Diary: 'It’s a bit stupid that way and only saves what stands out or triggers us emotionally. Unfortunately it’s not the best recipee for neither personal growth nor growth in business. But luckily it’s quite simple to change that pattern.'
    text2Diary_no: 'Den er litt dum på den måten og lagrer bare det som skiller seg ut og det som trigger oss emosjonelt. Dessverre er ikke dette den beste oppskriften for verken personlig vekst eller vekst innen business. Men heldigvis er det ganske enkelt å endre det mønsteret.'
    text3Diary: 'The learning diary is designed as a simple technique for you to empower your brain to make the things you really want to stand out … actually stand out. Simply highlight two things you’re happy about and one thing you can improve at the end of the day, and voilá – it’ll be like giving your brain a hug before you go to sleep.'
    text3Diary_no: 'Læringsdagboken er designet som en enkel teknikk for å hjelpe hjernen din lagre det du vil skal skille seg ut … faktisk skille seg ut. Bare tenk på to ting du er fornøyd med på slutten av dagen og en ting du kunne ha gjort bedre og voilá så blir det som å gi hjernen din en god klem før du legger deg.'

    close: 'Close'
    close_no: 'Lukk'
</translations>

<script>
export default {
    props: {
        currentStep: Number,
    },
    data(){
        return {
            text1:'',
            text2: '',
            text3: '',
            stepTitle: 'stepTitleJoy',
        }
    },
    watch: {

    },
    methods: {
        openLifeskill(){
          const routes = {
            0: {
              courseId: 'ls-70-no',
              stepId: 'd8d4aae3-3eee-4b0b-9493-447bb2254f74',
            },
            1: {
              courseId: 'ls-70-no',
              stepId: '9c4fd9a5-3d2e-4657-b677-ce9936ef6029',
            },
            2: {
              courseId: 'ls-70-no',
              stepId: 'b9f77e69-0df4-4385-a12e-5a60fb75a9c1',
            },
            3: {
              courseId: 'ls-70-no',
              stepId: '3cb30a71-f893-466b-abb2-22e712fdd14b',
            },
            4: {
              courseId: 'ls-70-no',
              stepId: 'b09741c7-baea-491a-9db0-baaa842e21c5',
            }
          };


          this.$router.push({
            name: 'ReadView',
            params: {
              activityName: 'read',
              ...routes[this.currentStep],
            }
          });

        },
        closeButton(){
            this.$emit('close')
        }
    },
    mounted(){
        if(this.currentStep===0){
            this.stepTitle = 'stepTitleJoy'
            this.text1 = "text1Joy"
            this.text2 = "text2Joy"
            this.text3 = "text3Joy"
        } else if(this.currentStep===1){
            this.stepTitle = 'stepTitleFrog'
            this.text1 = "text1Frog"
            this.text2 = "text2Frog"
            this.text3 = "text3Frog"
        } else if(this.currentStep===2){
            this.stepTitle = 'stepTitleFocus'
            this.text1 = "text1Focus"
            this.text2 = "text2Focus"
            this.text3 = "text3Focus"
        } else if(this.currentStep===3){
            this.stepTitle = 'stepTitlePlan'
            this.text1 = "text1Plan"
            this.text2 = "text2Plan"
            this.text3 = "text3Plan"
        } else if(this.currentStep===4){
            this.stepTitle = 'stepTitleDiary'
            this.text1 = "text1Diary"
            this.text2 = "text2Diary"
            this.text3 = "text3Diary"
        }
    }
}
</script>

<style scoped>
.PopoutContainer {
  background: rgba(11, 5, 65, 0.692);
  width: 100%;
  height: 100%;
}
.popoutWindow {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 90%;
  height: 46em;
  background-color: white;
  border-radius: 0.5em;
}
.closeButton {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    width: 5em;
    height: 2em;
    margin: 0.4em;
    border-radius: 1em;
    background-color: rgba(220, 221, 255, 0.767);
    color: rgba(0, 0, 0, 0.664);
    z-index: 10;
}
.closeButton:hover {
    cursor: pointer;
}
.titleTextContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 100%;
  padding-left: 3em;
  padding-right: 3em;
  margin-top: 3em;
  /* top: 5em; */
}
.title {
    font-size: 250%;
    font-weight: 600;
    padding-bottom: 0.5em;
    /* margin-top: 3em */
}
.textBox {
    text-align: left;
    width: 100%;
    background: none;
    border: none;
    color: rgb(32, 11, 65);
    font-size: 95%;
    font-weight: 500;
    font-family: Arial;
    padding-top: 0.7em;
    padding-bottom: 0.5em;
    overflow-x: hidden;
    overflow-y: auto;
    line-height: 1.3;
}
.text1 {
    font-weight: 700;
    font-size: 110%;
}
.bottomContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    text-align: left;
    width: 100%;
    height: 15em;
    /* bottom: 0; */
    padding-left: 3em;
    padding-right: 3em;
    padding-bottom: 3em;
}
.clickableArea {
    position: absolute;
    width: 100%;
    height: 8em;
    bottom:5em;
    z-index: 10;
}
.clickableArea:hover {
    background-color: rgba(0, 132, 255, 0.062);
    cursor: pointer;
}
.line{
  height: 1px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.137);
  margin-bottom: 1em;
  margin-top: 1em;
}
.learnMoreContainer {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    width: 100%;
}
.image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 6em;
    height: 6em;
    background-color:rgba(141, 141, 141, 0.548);
    border-radius: 0.8em;
    margin-right: 2em;
    overflow: hidden;
}
.imageAsset {
    height: 100%;
}
.stepNum {

}
.stepTitle {
    font-size: 180%;
    font-weight: 600;
}
.bottomLink {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    bottom: 0;
    padding-bottom: 2.2em;
    color: rgba(57, 127, 233, 0.664);
    z-index: 10; 
}
.bottomLink:hover {
    cursor: pointer;
    color: rgb(57, 183, 233);
    text-decoration: underline; 
}
@media screen and (min-width: 480px) {
    .popoutWindow {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        width: 35em;
        height: 42em;
        background-color: white;
    }
    .textBox {
        line-height: 1.4;
    }
    .bottomLink {
        padding-bottom: 2em;
    }
    .bottomContainer {
        padding-bottom: 3.6em;
    }
    .clickableArea {
        bottom: 5.3em;
    }
}

</style>
