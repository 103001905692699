<template>
    <div class="AnimationCanvas" :class="{ hidden: hideAnim }">
        <div ref="container" class="container" />
    </div>
</template>

<script>
import animationData from '../assets/memoman_new.json';
import lottie from 'lottie-web';
export default {
    props: {
        //idle, walk, down, up, stats check
        animationState: {
            type: String,
            default: 'idle',
            validator: function (value) {
                // The value must match one of these strings
                return (
                    [
                        'skip',
                        'idle',
                        'walk',
                        'set_joy',
                        'throw_joy',
                        'recieve_joy',
                        'set_frog',
                        'throw_frog',
                        'recieve_frog',
                        'set_focus',
                        'throw_focus',
                        'set_plan',
                        'throw_plan',
                        'set_diary',
                        'throw_diary',
                        'receive_final',
                        'receive_final_zoom',
                        'receive_joy'
                    ].indexOf(value) !== -1
                );
            }
        },
        headType: {
            type: String,
            default: 'circle',
            validator: function (value) {
                // The value must match one of these strings
                return ['circle', 'crown'].indexOf(value) !== -1;
            }
        }
    },
    data() {
        return {
            hideAnim: false
        };
    },
    watch: {
        animationState(animationState) {
            this.showAnimationByItsState(animationState);
        }
    },
    mounted() {
        this.anim = lottie.loadAnimation({
            container: this.$refs.container,
            renderer: 'canvas',
            loop: false,
            autoplay: false,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid meet'
            },
            animationData
        });

        this.animRecieve = lottie.loadAnimation({
            container: this.$refs.container,
            renderer: 'canvas',
            loop: true,
            autoplay: false,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid meet'
            },
            animationData
        });

        this.showAnimationByItsState(this.animationState);
    },
    methods: {
        animate(a, b, show) {
            this.hideAnim = false;
            this.anim.playSegments([a, b], true);
            this.anim.onComplete = () => {
                if (show) {
                    if (this.animationState === 'skip') {
                        this.hideAnim = true;
                    } else {
                        this.hideAnim = false;
                    }
                } else {
                    this.hideAnim = true;
                }
            };
        },
        animateRecieve(a, b, c, d, show) {
            this.hideAnim = false;
            this.anim.playSegments([a, b], true);
            this.anim.onComplete = () => {
                this.anim.playSegments([c, d], false);
            };
        },
        showAnimationByItsState(animationState) {
            if (animationState === 'skip') {
                this.hideAnim = true;
            } else if (animationState === 'set_joy') {
                this.animate(0, 1, true);
            } else if (animationState === 'throw_joy') {
                this.animate(0, 215, false);
            } else if (animationState === 'receive_joy') {
                // this.animateRecieve(1141, 1322, 1322, 1390, true);
                this.animate(1820, 2100, false);
            } else if (animationState === 'set_frog') {
                this.animate(217, 218, true);
            } else if (animationState === 'throw_frog') {
                this.animate(0, 210, false);
                // this.animate(217, 427, false);
            } else if (animationState === 'recieve_frog') {
                this.animate(1085, 1337, true);
            } else if (animationState === 'set_focus') {
                this.animate(436, 437, true);
            } else if (animationState === 'throw_focus') {
                this.animate(0, 200, false);
                // this.animate(436, 643, false);
            } else if (animationState === 'set_plan') {
                this.animate(654, 655, true);
            } else if (animationState === 'throw_plan') {
                this.animate(0, 204, false);
                // this.animate(654, 850, false);
            } else if (animationState === 'set_diary') {
                this.animate(872, 873, true);
            } else if (animationState === 'throw_diary') {
                this.animate(0, 200, false);
                // this.animate(872, 1075, false);
            } else if (animationState === 'receive_final') {
                this.animateRecieve(1390, 1648, 1648, 1733, true);
            } else if (animationState === 'receive_final_zoom') {
                this.animate(2026, 2100, false);
            }
        }
    },
    beforeDestroy() {
        this.anim.destroy();
        this.animRecieve.destroy();
    }
};
</script>

<style scoped>
.AnimationCanvas {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.AnimationCanvas.hidden {
    visibility: hidden;
}
.container {
    height: 60em;
    width: 60em;
    position: relative;
    bottom: 0;
}

@media (max-height: 650px) {
    .container {
        height: 55em;
    width: 55em;
    position: relative;
    bottom: 0em;
    }
}
</style>
