<template>
    <div class="FocusView">
        <div v-show="showTitle && !dayplanDone" class="titleContainer">
            <div class="mainCategoryTitle">
                {{ $t('mainCategoryTitle') }}
            </div>
            <div class="mainTitle">
                {{ $t('mainTitle') }}
            </div>

            <div class="subTitle">
                {{ $t('subTitle') }}
            </div>
        </div>

        <div v-show="showDoneTitle" class="doneTitle">
            <h1 v-if="weeklyBadgeDone && showWeeklyProgress"> {{ $t('doneTitleYoure') }} </h1>
            <h1 v-if="weeklyBadgeDone && showWeeklyProgress"> {{ $t('happinessMedallion') }} </h1>
             
            <h1 v-if="!weeklyBadgeDone || weeklyBadgeDone && !showWeeklyProgress">{{ $t('doneTitleCongrats') }}</h1>
            <h2 v-if="showstepDoneTitle">{{ $t('doneTitle') }}</h2>
            <h2 v-if="showAllDoneTitle">{{ $t('doneAllTitle') }}</h2>
        </div>

        <AnimationCanvas
            v-if="showAnimation && !showWeeklyProgress"
            :animation-state="animationState"
            class="animationCanvas"
        />

        <transition appear :css="false" @before-enter="beforeEnter" @enter="onEnter" @leave="leave">
            <PlanCard v-if="showPlanCard" :skip-anim="skipAnim" @completed="onCompleted()" />
        </transition>

        <WeeklyProgressStep
            v-if="showWeeklyProgress"
            class="weeklyProgress"
            :step="step"
            @weeklyBadgeDone="weeklyBadgeDone = true"
        />

        <BottomBar
            v-if="showBottomBar"
            :card-state="cardState"
            :step="step"
            @start-card="start()"
            @next-card="nextCard()"
            @skip-animation="skipAnimation()"
            @open-popout-window="$emit('open-popout-window')"
        />
    </div>
</template>
<translations>
  mainCategoryTitle: 'PRODUCTIVITY'
  mainCategoryTitle_no: 'PRODUKTIVITET'

  mainTitle: 'Daily Plan'
  mainTitle_no: 'Dagsplanen'

  subTitle: 'Planning tomorrow’s work today is like a spa for your brain, and a brain hack for the work-life balance we all deserve.'
  subTitle_no: 'Det å planlegge morgendagen i dag er som et spa for hjernen, og en hjernehack for balansen mellom jobb og fritid som vi jo alle fortjener.'

  moreForYour: 'more for your'
  moreForYour_no: 'til for'

  productivity: 'Productivity Diamond'
  productivity_no: 'produktivitetsdiamant'

  doneTitleYoure: "You're a"
  doneTitleYoure_no: 'Du er en'
  doneTitleCongrats: 'Congrats,'
  doneTitleCongrats_no: 'Grattis,'

  doneTitle: ' you finished your daily plan!'
  doneTitle_no: ' du er ferdig med dagsplanen!'

  doneAllTitle: ' you finished your mental hygiene!'
  doneAllTitle_no: ' du er ferdig med dagens mentalhygiene!'

</translations>
<script>
import PlanCard from '../components/PlanCard.vue';
import AnimationCanvas from '../components/AnimationCanvas.vue';
import BottomBar from '../components/BottomBar.vue';
import { TweenMax } from 'gsap';
import WeeklyProgressStep from '../components/WeeklyProgressStep.vue';

export default {
    components: {
        AnimationCanvas,
        WeeklyProgressStep,
        PlanCard,
        BottomBar,
    },
    data: function () {
        return {
            step: 3,
            animationState: 'idle',
            finishedFrogs: 0,
            showBottomBar: true,
            cardState: 'ready', // done, ready
            showWeeklyProgress: false,
            showPlanCard: false,
            showAnimation: true,
            weeklyBadgeDone: false,
            doneFinal: false,
            skipAnim: false,
        };
    },

    props: {},

    mounted() {
        this.finishedFrogs = this.finishedFrogsToday;
        this.animationState = 'set_plan';
        if (this.dayplanDone) {
            this.showWeeklyProgress = true;
        }
    },

    methods: {
        skipAnimation() {
            this.skipAnim = true;
            this.start();
        },

        start() {
            this.cardState = 'editing';
            this.showBottomBar = false;
            this.showWeeklyProgress = false;
            this.hideAnimationAndShowJoyCard();
        },

        hideAnimationAndShowJoyCard() {
            this.showPlanCard = true;
            if (this.skipAnim) {
                this.showAnimation = false;
            } else {
                this.animationState = 'throw_plan';
                setTimeout(() => {
                    this.showAnimation = false;
                }, 3350);
            }
        },

        onCompleted() {
            console.log('onCompleted()');
            this.cardState = 'done';
            this.receiveAnimation();
            this.$emit('completed');
        },

        receiveAnimation() {
            this.showAnimation = true;
            this.showPlanCard = false;
            this.animationState = 'receive_joy';
            this.showWeekly();
        },

        showWeekly() {
            if (this.allDone) {
                this.showBottomBar = true;
                this.animationState = 'receive_final';
            } else {
                this.animationState = 'receive_joy';
                setTimeout(() => {
                    this.showAnimation = false;
                    this.showWeeklyProgress = true;
                    this.showBottomBar = true;
                }, 4100);
            }
        },

        nextCard() {
            if (!this.allDone || (this.allDone && this.doneFinal)) {
                this.cardState = 'ready';
                this.$emit('next-card');
            } else {
                this.doneFinal = true;
                this.animationState = 'receive_final_zoom';
                setTimeout(() => {
                    this.showWeeklyProgress = true;
                }, 620);
            }
        },

        leave(el, done) {
            if (!this.skipAnim) {
                TweenMax.to(el, 1, { y: -1000, onComplete: done }).duration(0.6);
            } else {
                TweenMax.to(el, 1, { y: -1000, onComplete: done }).duration(0);
            }
        },

        beforeEnter(el) {
            if (!this.skipAnim) {
                TweenMax.set(el, { y: -window.innerHeight, rotation: 5 });
            } else {
                TweenMax.set(el, { y: -5, rotation: 0 });
            }
        },

        onEnter(el, done) {
            if (!this.skipAnim) {
                TweenMax.to(el, 1, { y: 15, onComplete: done, delay: 3 }).duration(0.5);
                TweenMax.to(el, 1, { rotation: -5, onComplete: done, delay: 3 }).duration(0.2);
                TweenMax.to(el, 1, {
                    rotation: 0,
                    onComplete: done,
                    delay: 3.3,
                }).duration(0.5);
                TweenMax.to(el, 1, { y: -5, onComplete: done, delay: 3.5 }).duration(0.5);
            }
        },
    },

    computed: {
        showTitle() {
            return this.cardState === 'ready' && !this.showPlanCard;
        },
        finishedFrogsToday() {
            const dayplan = this.$store.getters['moduleMentalhygiene/dayplanToday'];
            return dayplan ? dayplan.finishedFrogs : 0;
        },
        dayplanDone() {
            return this.$store.getters['moduleMentalhygiene/isDayplanFinishedToday'];
        },
        allDone() {
            return this.$store.getters['moduleMentalhygiene/doneToday'];
        },
        showDoneTitle() {
            if (this.dayplanDone && this.cardState === 'done') {
                return true;
            } else if (this.dayplanDone && this.cardState === 'ready') {
                return true;
            } else {
                return false;
            }
        },
        showAllDoneTitle(){
            if(!this.weeklyBadgeDone && this.allDone){
                return true
            } else if(this.weeklyBadgeDone && !this.showWeeklyProgress && this.allDone){
                return true
            }
        },
        showstepDoneTitle(){
             if(!this.weeklyBadgeDone && !this.allDone){
                return true
            } else if(this.weeklyBadgeDone && !this.showWeeklyProgress && !this.allDone){
                return true
            }
        }
    },

    filters: {},
};
</script>

<style lang="scss" scoped>
.FocusView {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.titleContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    height: 4em;
    padding-left: 1em;
    padding-right: 1em;
    top: 2em;
    z-index: 1000;
}
.mainCategoryTitle {
    color: rgba(240, 248, 255, 0.418);
    font-size: 100%;
}
.mainTitle {
    color: rgba(255, 255, 255, 0.918);
    font-size: 280%;
    font-weight: 600;
}
.subTitle {
    color: rgba(255, 255, 255, 0.507);
    font-size: 100%;
    font-weight: 400;
    width: 24em;
    margin-top: 1.4em;
    margin-bottom: 0.7em;
}
.doneTitle {
    position: absolute;
    align-items: center;
    text-align: center;
    top: 2em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    h1 {
        font-family: Roboto;
        font-style: normal;
        font-weight: 600;
        font-size: 32.3077px;
        line-height: 15px;
        color: rgba(255, 255, 255, 0.918);
    }
    h2 {
        font-family: Roboto;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;
        color: #846eb8;
    }
    font-size: 210%;
    font-weight: 600;
    opacity: 1;
}
.AnimationCanvas {
  position: absolute;
  pointer-events: none;
}
@media (max-width: 620px) {
    .doneTitle {
        top: 0em;
        h1 {
            font-size: 28px;
        }
        h2 {
            font-size: 17px;
        }
        font-size: 210%;
        font-weight: 600;
        opacity: 1;
    }         
}
@media (max-height: 650px) {
    .AnimationCanvas {
        margin-top: 3em;
    }
    .titleContainer {
        top: 0.3em;
    }
}
</style>
