<template>
  <div
    :class="{ active }"
      class="JoyCard">

    <div class="photoFrame">
      <transition-group name="image" mode="in-out">
        <div
            v-show="!imageUrl"
            key="animKey">
          <div ref="imageContainer"></div>
        </div>
        <div
            v-if="imageUrl"
            class="photo"
            key="imageKey">
          <img
              :src="imageUrl"
              :alt="imageAlt"
              class="photo">
          <div
              v-if="canShuffle"
              @click="$emit('shuffle')"
              class="shuffle">
            <svgicon
                icon="shuffle"
                color="white"
                width="18"
                height="18" />
          </div>
        </div>
      </transition-group>
    </div>

    <textarea
        :value="value"
        @input="$emit('input', $event.target.value)"
        oninput="this.value = this.value.replace(/\n/g,'')"
        ref="inputField"
        maxlength="72"
        spellcheck="false"
        class="textField"
        :placeholder="$t('placeholder')"
        @keyup.enter="post()"
        @blur="post()"
        @focus="$emit('editing')"
        rows="2"
        cols="30" />

      <div v-if="imageBylineUrl" class="byline">
        Photo:
        <a target="_blank" :href="imageBylineUrl">{{ image.user.name }}</a> on <a target="_blank" href="https://unsplash.com/?utm_source=memolife&utm_medium=referral">Unsplash</a>
      </div>
  </div>
</template>

<translations>
  placeholder: 'Type your joy here'
  placeholder_no: 'Tast gleden din her'
</translations>

<script>
import animationData from '../assets/joy_card_image.json';
import lottie from 'lottie-web';

export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    active: {
      type: Boolean,
      default: false,
    },
    playCardAnimation: Boolean,
    image: {
      type: Object,
      default: function() { return {}},
    },
    canShuffle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      animationCompleted: false
    };
  },
  computed: {
    imageAlt() {
      if (this.image) {
        return this.image.alt_description;
      }
      return ""
    },
    imageUrl() {
      if (this.image && this.image.urls) {
        return this.image.urls.small;
      }
      return "";
    },
    imageBylineUrl() {
        if (this.image && this.image.user) {
          return `${this.image.user.links.html}?utm_source=memolife&utm_medium=referral`;
        }
      return "";
    }
  },
  watch: {
    playCardAnimation(v){
      if(v){
        this.animateJoyPhoto('sun');
        this.editAfterAnimationFinishes();
      }
    }
  },
  mounted() {
    this.setupAnimation();
    if (this.playCardAnimation) {
      this.animateJoyPhoto('sun');
      this.editIfEmpty();
    }
  },

  beforeDestroy() {
    this.anim.destroy();
  },

  methods: {
    setupAnimation() {
      this.anim = lottie.loadAnimation({
        container: this.$refs.imageContainer,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid meet',
        },
        animationData,
      });
    },

    editAfterAnimationFinishes() {
      setTimeout(() => {
        this.editIfEmpty();
      }, 1000);
    },

    editIfEmpty() {
      this.$refs.inputField.value.length===0? this.edit() : false;
    },

    afterEnter() {
      this.animateJoyPhoto('sun');
    },

    animateJoyPhoto(a) {
      if (this.animationCompleted) {
        return;
      }
      if (a === 'sun') {
        this.anim.playSegments([0, 133], true);
        this.anim.show();
        this.anim.onComplete = () => {
          this.anim.show();
          this.animationCompleted = true;
        };
      } else if (a === 'memoman1') {
        this.anim.show();
        this.anim.playSegments([133, 137], true);
        this.anim.onComplete = () => {
          this.anim.show();
        };
      }
    },

    post() {
      this.$emit('posted');
      this.animateJoyPhoto('memoman1');
      this.$refs.inputField.blur();
    },

    edit() {
      this.$emit('editing');
      this.$refs.inputField.focus();
    },
  },
};
</script>

<style>
body {
  background: #6632ab;
}
</style>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Caveat');
.JoyCard {
  position: relative;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(217, 204, 232);
  width: 18em;
  height: 20.7em;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 1.2em;
  padding-bottom: 1em;
  border-radius: 0.4em;
  box-shadow: 10px 10px 10px 10px rgba(45, 45, 45, 0.079);
  /* border: 1px solid red; */
}
.JoyCard.active {
  background: rgb(251, 248, 255);
}
.photoFrame {
  background-color: rgba(253, 167, 29, 0);
  height: 192px;
  max-height: 192px;
}
.photo {
  width: 100%;
  height: 192px;
  max-height: 100%;
  object-fit: cover;
}

.shuffle {
  position: fixed;
  right: 25px;
  top: 175px;
  background-color: rgba(0,0,0,.3);
  padding: 4px;
  cursor: pointer;
}

.image-leave, .image-enter-to {
  position: relative;
}

.image-enter-active, .image-leave-active {
  transition: opacity .5s;
}

.image-enter, .image-leave-to {
  position: absolute;
  opacity: 0;
}

.byline {
  width: 100%;
  text-align: right;
  opacity: 0.8;
  font-size: 0.6em;
  padding-top: 1em;
}

.byline >
a,a:link, a:hover,a:visited {
  color: #2c3e50;
}


textarea:focus,
select:focus {
  outline: none;
}
textarea {
  width: 95%;
  height: 20em;
  margin: px 0;
  background: none;
  border: none;
  color: rgb(40, 19, 71);
  font-family: 'Caveat';
  font-size: 15px;
  font-weight: 600;
  resize: none;
  text-align: start;
  overflow: hidden;
  padding-top: 1em;
  display: table-cell;
  vertical-align: middle;
}
textarea.postedText {
  pointer-events: none;
  text-align: center;
}
textarea::-webkit-input-placeholder {
  color: rgba(35, 12, 81, 0.378);
  font-weight: normal;
  font-size: 17px;
}
.spacer {
  height: 1em;
}
@media screen and (min-width: 480px) {
  textarea {
    font-size: 18px;
  }
}
</style>
