<template>
    <div class="FrogContainer">
        <div v-show="showingButtons" class="titleContainer">
            <div v-show="showingButtons" class="categoryTitle">
                {{ $t('categoryTitle') }}
            </div>
            <div class="title">
                {{ $t('title') }}
            </div>
        </div>
        <div ref="imageContainer" class="photoFrame" />
        <div :class="{ active: showingButtons }" class="frogsProgressBarContainer">
            <div style="color: white"> {{ frogsAdded }} / {{ frogCount }} {{ $t('frogs') }} </div>
            <div class="frogsProgressBarWrapper">
                <div :class="{ active: frogsAdded >= 1 }" class="frogProgressBar" />
                <div v-show="frogCount > 1" :class="{ active: frogsAdded >= 2 }" class="frogProgressBar" />
                <div v-show="frogCount > 2" :class="{ active: frogsAdded === 3 }" class="frogProgressBar" />
            </div>
        </div>
        <div v-show="showingButtons" class="buttons">
            <div class="stepsCountText"> step 1/1 </div>
            <div class="prNextButtonsContainer">
                <div @click="removeFrog()" class="previousButton" :class="{ disabled: frogsAdded === 0 }">
                    <img src="../assets/buttons/minus_button_mentalhygiene.svg" height="60px" width="60px" />
                </div>
                <div @click="addFrog()" class="nextButton" :class="{ disabled: frogsAdded === frogCount }">
                    <img src="../assets/buttons/plus_button_mentalhygiene.svg" height="60px" width="60px" />
                </div>
            </div>
            <div @click="done()" class="doneButton" :class="{ active: frogsAdded > 0 }">
                {{ $t('doneButton') }}
            </div>
        </div>
    </div>
</template>

<translations>
  frogs: 'frogs'
  frogs_no: 'frosker'
  categoryTitle: 'PRODUCTIVITY'
  categoryTitle_no: 'PRODUKTIVITET'
  title: 'Kiss your next frog'
  title_no: 'Kyss din neste frosk'

  doneButton: 'Done'
  doneButton_no: 'Ferdig'
</translations>

<script>
import animationData from '../assets/frog_mentalhygiene.json';
import lottie from 'lottie-web';
import { mapActions } from 'vuex';
import moment from 'moment';

export default {
    components: {},
    props: {
        animateFrog: Boolean,
    },
    data() {
        return {
            frogAnimationState: 'idle',
            frogCount: 2,
            showingButtons: false,
            addFButtonTitle: 'Add Frog',
            frogsAdded: 0,
        };
    },
    computed: {
        plannedFrogs() {
            const dayplan = this.$store.getters['moduleMentalhygiene/dayplanYesterday'];
            return dayplan && dayplan.plannedFrogs !== 0 ? dayplan.plannedFrogs : 3;
        },
        plannedFrogsBackend() {
            const dayplan = this.$store.getters['moduleMentalhygiene/dayplanToday'];
            return dayplan ? dayplan.plannedFrogs : 0;
        },
        plannedTasksBackend() {
            const dayplan = this.$store.getters['moduleMentalhygiene/dayplanToday'];
            return dayplan ? dayplan.plannedTasks : 0;
        },
        finishedTasksBackend() {
            const dayplan = this.$store.getters['moduleMentalhygiene/dayplanToday'];
            return dayplan ? dayplan.finishedTasks : 0;
        },
        purposeBackend() {
            const dayplan = this.$store.getters['moduleMentalhygiene/dayplanToday'];
            return dayplan ? dayplan.purpose : 1;
        },
        productivityBackend() {
            const dayplan = this.$store.getters['moduleMentalhygiene/dayplanToday'];
            return dayplan ? dayplan.productivity : 1;
        },
        finishedFrogsToday() {
            const dayplan = this.$store.getters['moduleMentalhygiene/dayplanToday'];
            return dayplan ? dayplan.finishedFrogs : 0;
        },
    },
    watch: {
        frogsAdded(v) {
            if (this.frogCount === 3) {
                if (v === 0) {
                    this.frogAnimationState = 'frog0';
                } else if (v === 1) {
                    this.frogAnimationState = 'frog1';
                } else if (v === 2) {
                    this.frogAnimationState = 'frog2';
                    this.addFButtonTitle = 'Add Frog';
                } else if (v === 3) {
                    this.frogAnimationState = 'frog3';
                    this.addFButtonTitle = 'Finish';
                }
            } else if (this.frogCount === 2) {
                if (v === 0) {
                    this.frogAnimationState = 'frog0';
                } else if (v === 1) {
                    this.frogAnimationState = 'frog1';
                } else if (v === 2) {
                    this.frogAnimationState = 'frog3';
                    this.addFButtonTitle = 'Finish';
                }
            } else if (this.frogCount === 1) {
                if (v === 0) {
                    this.frogAnimationState = 'frog0';
                } else if (v === 1) {
                    this.frogAnimationState = 'frog3';
                    this.addFButtonTitle = 'Finish';
                }
            }
        },
        frogAnimationState(v) {
            if (v === 'frog0') {
                this.animateRecieve(0, 78, 78, 207);
            } else if (v === 'frog1') {
                this.animate(201, 650);
            } else if (v === 'frog2') {
                this.animate(666, 886);
            } else if (v === 'frog3') {
                this.animate(914, 1066);
            }
        },
    },
    mounted() {
        this.frogCount = this.plannedFrogs;

        this.anim = lottie.loadAnimation({
            container: this.$refs.imageContainer,
            renderer: 'canvas',
            loop: false,
            autoplay: false,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid meet',
            },
            //path: "joy_card_image.json",
            animationData,
        });
        if (this.animateFrog) {
            this.frogsAdded = this.finishedFrogsToday;
            this.setFrogAnimationState();
            this.showingButtons = true;
        } else {
            setTimeout(() => {
                this.frogsAdded = this.finishedFrogsToday;
                this.setFrogAnimationState();
                setTimeout(() => {
                    this.showingButtons = true;
                }, 500);
            }, 3500);
        }
    },
    beforeDestroy() {
        this.anim.destroy();
    },
    methods: {
        setFrogAnimationState() {
            if (this.frogsAdded === 0) {
                this.frogAnimationState = 'frog0';
            } else if (this.frogsAdded === 1) {
                this.frogCount === 3 || this.frogCount === 2
                    ? (this.frogAnimationState = 'frog1')
                    : (this.frogAnimationState = 'frog3');
            } else if (this.frogsAdded === 2) {
                this.frogCount === 2 ? (this.frogAnimationState = 'frog3') : (this.frogAnimationState = 'frog2');
            } else if (this.frogsAdded === 3) {
                this.frogAnimationState = 'frog3';
            }
        },

        animate(a, b) {
            this.anim.playSegments([a, b], true);
            this.anim.show();
            this.anim.onComplete = () => {
                this.anim.show();
            };
        },
        animateRecieve(a, b, c, d) {
            this.hideAnim = false;
            this.anim.playSegments([a, b], true);
            this.anim.onComplete = () => {
                this.anim.playSegments([c, d], true);
            };
        },
        done() {
            if (this.frogsAdded > 0) {
                this.$emit('completed');
                const today = moment();
                const dayplan = this.$store.getters['moduleMentalhygiene/dayplanToday'];
                if (dayplan) {
                    this.$store.dispatch('moduleMentalhygiene/updateDayplan', {
                        nodeId: dayplan.nodeId,
                        finishedTasks: this.finishedTasksBackend,
                        finishedFrogs: this.frogsAdded,
                        productivity: this.productivityBackend,
                        purpose: this.purposeBackend,
                        plannedFrogs: this.plannedFrogsBackend,
                        plannedTasks: this.plannedTasksBackend,
                    });
                } else {
                    this.$store.dispatch('moduleMentalhygiene/createDayplan', {
                        finishedTasks: this.finishedTasksBackend,
                        finishedFrogs: this.frogsAdded,
                        productivity: this.productivityBackend,
                        purpose: this.purposeBackend,
                        plannedFrogs: this.plannedFrogsBackend,
                        plannedTasks: this.plannedTasksBackend,
                        date: today,
                    });
                }
            }
        },
        removeFrog() {
            if (this.frogsAdded > 0) {
                this.frogsAdded--;
            }
        },
        async addFrog() {
            const userId = this.$store.state.moduleAuth.profile._id;
            if (
                (this.frogCount === 3 && this.frogsAdded < 3) ||
                (this.frogCount === 2 && this.frogsAdded < 2) ||
                (this.frogCount === 1 && this.frogsAdded < 1)
            ) {
                this.frogsAdded++;
            }
        },
    },
};
</script>

<style scoped>
.photoFrame {
    /* max-width: 70em; */
    height: 55%;
    width: 50em;
    padding-left: 1em;
}
.FrogContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 1em;
    left: 0;
    bottom: 0;
    right: 0;
}
.add.fade {
    opacity: 0.3;
    pointer-events: none;
}
.frogsProgressBarContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 7em;
    width: 15em;
    margin-bottom: 6em;
    visibility: hidden;
}
.frogsProgressBarContainer.active {
    visibility: visible;
}
.frogsProgressBarWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 4em;
}
.frogProgressBar {
    flex: 1;
    height: 0.2em;
    background-color: rgb(34, 85, 105);
    margin: 0.2em;
}
.frogProgressBar.active {
    background-color: rgb(86, 202, 248);
}
.buttons {
    /* border: 1px solid red; */
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    bottom: 1em;
    padding-left: 1em;
    padding-right: 2em;
    /* visibility: hidden; */
}
.prNextButtonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 10em;
}
.nextButton {
    opacity: 1;
}
.nextButton.disabled {
    opacity: 0.5;
}
.previousButton {
    opacity: 1;
}
.previousButton.disabled {
    opacity: 0.5;
}
.doneButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 5em;
    width: 5em;
    height: 5em;
    opacity: 0.4;
    background-color: rgb(100, 203, 225);
    color: white;
    font-size: 12px;
}
.doneButton.active {
    opacity: 1;
}
.doneButton:hover {
    cursor: pointer;
}
.previousButton:hover {
    cursor: pointer;
}
.nextButton:hover {
    cursor: pointer;
}
.titleContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    height: 4em;
    padding-left: 1em;
    padding-right: 1em;
    top: 2em;
}
.categoryTitle {
    color: rgba(240, 248, 255, 0.418);
    font-size: 100%;
}
.title {
    color: rgba(255, 255, 255, 0.918);
    font-size: 240%;
    font-weight: 600;
}
.stepsCountText {
    color: rgba(255, 255, 255, 0.719);
    display: flex;
    flex-direction: column;
    justify-content: center;
}
@media (max-width: 620px) {
.titleContainer {
    height: 3.4em;
    padding-left: 1em;
    padding-right: 1em;
    top: 0em;
}
.categoryTitle {
    font-size: 85%;
}
.title {
    font-size: 160%;
}
}
</style>
