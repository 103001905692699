<template>
    <div class="MentalHygieneView">
        <div class="exitButton" @click="goToDashboard">
            <svgicon class="svg-icon" icon="dashboard-icon" />
        </div>
        <PlanCheck v-if="cardState === 'planCheck'" @completed="(cardState = 'ready'), onSelect(step)" />

        <IntroPopoutWindow v-if="showPopoutWindow" class="modalDialog" :current-step="step" @close="closePopout()" />

        <TopBar v-if="cardState !== 'planCheck'" :index="step" @select="onSelect" />

        <div v-if="cardState !== 'planCheck'" class="mainContainer">
                <JoyView
                  v-if="showJoyView"
                  @completed="onCompleted()"
                  @next-card="nextCard()"
                  @open-popout-window="onPopoutWindow">
                </JoyView>

                <FrogView
                  v-if="showFrogView"
                  @completed="onCompleted()"
                  @next-card="nextCard()"
                  @open-popout-window="onPopoutWindow">
                </FrogView>

                <FocusView
                  v-if="showFocusView"
                  @completed="onCompleted()"
                  @next-card="nextCard()"
                  @open-popout-window="onPopoutWindow">
                </FocusView>

                <PlanView
                  v-if="showPlanView"
                  @completed="onCompleted()"
                  @next-card="nextCard()"
                  @open-popout-window="onPopoutWindow">
                </PlanView>

                <DiaryView
                  v-if="showDiaryView"
                  @completed="onCompleted()"
                  @next-card="nextCard()"
                  @open-popout-window="onPopoutWindow">
                </DiaryView>

        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import JoyView from './JoyView';
import FrogView from './FrogView';
import FocusView from './FocusView';
import PlanView from './PlanView';
import PlanCheck from '../components/PlanCheck.vue';
import DiaryView from './DiaryView'
import TopBar from '../components/TopBar.vue';
import IntroPopoutWindow from '../components/IntroPopoutWindow.vue';
import moment from 'moment';
import * as types from '@/store/mutation-types';

export default {
    components: {
        JoyView,
        FrogView,
        FocusView,
        PlanView,
        DiaryView,
        TopBar,
        PlanCheck,
        IntroPopoutWindow,
    },
    props: {
        setInitialTab: {
            type: Boolean,
            required: false,
        },

        stepNumber: {
            type: Number,
            required: false,
        },
    },
    data() {
        return {
            step: 0,
            cardState: 'planCheck',
            lifeskillNum: '34',
            skipAnim: false,
            showPopoutWindow: false,
            doneFinal: false,
        };
    },
    computed: {
        ...mapGetters({
            doneToday: 'moduleMentalhygiene/doneToday',
        }),

        showJoyView() {
          return this.step === 0;
        },
        showFrogView() {
          return this.step === 1;
        },
        showFocusView() {
          return this.step === 2;
        },
        showPlanView() {
          return this.step === 3;
        },
        showDiaryView() {
          return this.step === 4;
        },
        plannedFrogsYesterday() {
            const dayplan = this.$store.getters['moduleMentalhygiene/dayplanYesterday'];
            return dayplan ? dayplan.plannedFrogs : 0;
        },
        allDone() {
            return this.$store.getters['moduleMentalhygiene/doneToday'];
        },
    },

    watch: {
    },
    async mounted() {
        if (this.plannedFrogsYesterday !== 0) {
            this.cardState = 'ready';
        }
        if (this.setInitialTab) {
            this.onSelect(this.stepNumber);
        }
    },

    methods: {
        onSelect(idx) {
            this.step = idx;
            this.skipAnim = true;
            if (this.cardState === 'done') {
                this.cardState = 'ready';
                this.skipAnim = false;
            } else if (this.cardState === 'ready') {
                this.skipAnim = false;
            }
        },

        onPopoutWindow() {
            this.showPopoutWindow = !this.showPopoutWindow;
        },

        closePopout() {
            this.showPopoutWindow = false;
        },

        nextCard() {
          this.step++;
        },

        onCompleted(t) {
            this.awardBrainDate();
            this.cardState = 'done';
            this.skipAnim = false;
            this.awardLifekey();
        },

        awardLifekey(){
            this.$store.commit('moduleMentalhygiene/' + types.MH_DAILY_COMPLETED);
        },

        awardBrainDate(){
          this.$store.commit('moduleMentalhygiene/' + types.MH_COMPLETED, { step: this.step });
        },

        goToDashboard() {
            this.$router.push({
                name: 'DashboardHomeView',
            });
        },
    },
};
</script>

<style scoped lang="scss">
.MentalHygieneView {
    background-color: #2d1860;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}
.MentalHygieneView.changeColor {
    background-color: #271355;
}
.mainContainer {
    flex: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.AnimationCanvas {
    position: absolute;
    pointer-events: none;
}
.modalDialog {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 10000;
}
.popoutLink {
    font-weight: 400;
    opacity: 1;
    text-decoration: underline;
}
.popoutLink:hover {
    opacity: 1;
    cursor: pointer;
    color: rgb(255, 255, 255);
}

.exitButton {
    position: absolute;
    top: 1.4em;
    left: 1.4em;
    background-color: inherit;
    padding: 0.5em;
    border-radius: 50%;
    cursor: pointer;
    z-index: 2;
    @media (max-width: 620px) {
        top: 1.2em;
        left: 1.2em;
    }
}

.svg-icon {
    display: block;
    width: 20px;
    height: 20px;
    fill: white;
    @media (max-width: 620px) {
        width: 15px;
        height: 15px;
    }
}
</style>
