<template>
  <div class="Sliders">
    <div class="slidersAnimCont">
      <div :class="{ active: showSliders }" class="slidersCont">
        <div class="tasksSliderContainer">
          <input
            v-model="tasksSliderValue"
            type="range"
            min="0"
            :max="currentStep === 0 && checkYesterday ? tasksSliderMaxSteps : currentStep === 0 ? '9' : '8'"
            step="1"
            class="tasksSlider"
          />
          <div class="tasksSliderTextContainer">
            <div v-show="currentStep === 0" :class="{ active: tasksSliderValue === '0' }" class="tasksSliderText">
              0
            </div>
            <div
              :class="{ active: currentStep === 0 ? tasksSliderValue === '1' : tasksSliderValue === '0' }"
              class="tasksSliderText"
            >
              1
            </div>
            <div
              v-show="currentStep === 0 && checkYesterday ? plannedTasksBackend > 1 : true"
              :class="{ active: currentStep === 0 ? tasksSliderValue === '2' : tasksSliderValue === '1' }"
              class="tasksSliderText"
            >
              2
            </div>
            <div
              v-show="currentStep === 0 && checkYesterday ? plannedTasksBackend > 2 : true"
              :class="{ active: currentStep === 0 ? tasksSliderValue === '3' : tasksSliderValue === '2' }"
              class="tasksSliderText"
            >
              3
            </div>
            <div
              v-show="currentStep === 0 && checkYesterday ? plannedTasksBackend > 3 : true"
              :class="{ active: currentStep === 0 ? tasksSliderValue === '4' : tasksSliderValue === '3' }"
              class="tasksSliderText"
            >
              4
            </div>
            <div
              v-show="currentStep === 0 && checkYesterday ? plannedTasksBackend > 4 : true"
              :class="{ active: currentStep === 0 ? tasksSliderValue === '5' : tasksSliderValue === '4' }"
              class="tasksSliderText"
            >
              5
            </div>
            <div
              v-show="currentStep === 0 && checkYesterday ? plannedTasksBackend > 5 : true"
              :class="{ active: currentStep === 0 ? tasksSliderValue === '6' : tasksSliderValue === '5' }"
              class="tasksSliderText"
            >
              6
            </div>
            <div
              v-show="currentStep === 0 && checkYesterday ? plannedTasksBackend > 6 : true"
              :class="{ active: currentStep === 0 ? tasksSliderValue === '7' : tasksSliderValue === '6' }"
              class="tasksSliderText"
            >
              7
            </div>
            <div
              v-show="currentStep === 0 && checkYesterday ? plannedTasksBackend > 7 : true"
              :class="{ active: currentStep === 0 ? tasksSliderValue === '8' : tasksSliderValue === '7' }"
              class="tasksSliderText"
            >
              8
            </div>
            <div
              v-show="currentStep === 0 && checkYesterday ? plannedTasksBackend > 8 : true"
              :class="{ active: currentStep === 0 ? tasksSliderValue === '9' : tasksSliderValue === '8' }"
              class="tasksSliderText"
            >
              9
            </div>
          </div>
        </div>
        <div class="frogsSliderContainer">
          <input
            v-model="frogsSliderValue"
            type="range"
            min="0"
            :max="currentStep === 0 && checkYesterday ? frogsSliderMaxSteps : currentStep === 0 ? '3' : '2'"
            step="1"
            class="frogsSlider"
          />
          <div class="frogsSliderTextContainer">
            <div v-show="currentStep === 0" :class="{ active: frogsSliderValue === '0' }" class="frogsSliderText">
              0
            </div>
            <div
              :class="{ active: currentStep === 0 ? frogsSliderValue === '1' : frogsSliderValue === '0' }"
              class="frogsSliderText"
            >
              1
            </div>
            <div
              v-show="currentStep === 0 && checkYesterday ? plannedFrogsBackend > 1 : true"
              :class="{ active: currentStep === 0 ? frogsSliderValue === '2' : frogsSliderValue === '1' }"
              class="frogsSliderText"
            >
              2
            </div>
            <div
              v-show="currentStep === 0 && checkYesterday ? plannedFrogsBackend > 2 : true"
              :class="{ active: currentStep === 0 ? frogsSliderValue === '3' : frogsSliderValue === '2' }"
              class="frogsSliderText"
            >
              3
            </div>
          </div>
        </div>
      </div>
      <div v-show="showSliders" class="iconsText">
        <div class="tasksText">
          {{ $t('tasks') }}
        </div>
        <div class="frogsText">
          {{ $t('frogs') }}
        </div>
      </div>
      <div class="animationWrapper">
        <div ref="imageContainer" class="animationContainer" />
      </div>
    </div>
  </div>
</template>

<translations>
  tasks: 'Tasks'
  tasks_no: 'Oppgaver'
  frogs: 'Frogs'
  frogs_no: 'Frosker'
</translations>

<script>
import animationData from '../../assets/plan_intro_icons.json';
import lottie from 'lottie-web';
import moment from 'moment';

export default {
  props: {
    currentStep: Number,
    skipAnim: Boolean,
    finishedTasks: Number,
    finishedFrogs: Number,
    plannedTasks: Number,
    plannedFrogs: Number,
    plannedTodayTasks: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      tasksSliderValue: 0,
      frogsSliderValue: 0,
      frogsSliderMaxSteps: '1',
      tasksSliderMaxSteps: '1',
      animationState: 'initial',
      showSliders: false,
    };
  },
  computed: {
    checkYesterday() {
      const yesterday = this.$store.getters['moduleMentalhygiene/dayplanYesterday'];
      return yesterday ? true : false;
    },
    plannedFrogsBackend() {
      const dayplan = this.$store.getters['moduleMentalhygiene/dayplanYesterday'];
      return dayplan && dayplan.plannedFrogs !== 0 ? dayplan.plannedFrogs : 3;
    },
    plannedTasksBackend() {
      const dayplan = this.$store.getters['moduleMentalhygiene/dayplanYesterday'];
      return dayplan && dayplan.plannedTasks !== 0 ? dayplan.plannedTasks : this.plannedTodayTasks;
    },
    finishedTasksBackend() {
      const dayplan = this.$store.getters['moduleMentalhygiene/dayplanToday'];
      return dayplan ? dayplan.finishedTasks : 0;
    },
    finishedFrogsToday() {
        const dayplan = this.$store.getters['moduleMentalhygiene/dayplanToday'];
        return dayplan ? dayplan.finishedFrogs : 0;
    },
  },
  watch: {
    frogsSliderValue(v) {
      if (this.currentStep === 4) {
        // this.$store.dispatch('moduleMentalhygiene/updateDayplan', {plannedFrogs:parseInt(v)+1})
        this.$emit('setPlannedFrogs', parseInt(v) + 1);
      } else {
        // const today = moment().format('MMMM Do YYYY, h:mm:ss a');
        const today = new Date().toDateString();
        const frogsNum = parseInt(v);
        this.$emit('setFinishedFrogs', frogsNum);
      }
    },
    tasksSliderValue(v) {
      if (this.currentStep === 4) {
        // this.$store.dispatch('moduleMentalhygiene/updateDayplan', {plannedFrogs:parseInt(v)+1})
        this.$emit('setPlannedTasks', parseInt(v) + 1);
      } else {
        this.$emit('setFinishedTasks', v);
      }
    },
    currentStep(v) {
      if (v === 0) {
        this.tasksSliderValue = this.finishedTasks.toString();
        this.frogsSliderValue = this.finishedFrogs.toString();
      } else if (v === 4) {
        this.tasksSliderValue = this.plannedFrogs === 0 ? '0' : (this.plannedTasks - 1).toString();
        this.frogsSliderValue = this.plannedFrogs === 0 ? '0' : (this.plannedFrogs - 1).toString();
      }
    },
    animationState(v) {
      if (v === 'skip_intro') {
        this.animate(239, 240);
      } else if (v === 'plan_intro') {
        this.animate(0, 240);
      } else if (v === 'plan_outro') {
        this.animate(240, 241);
      }
    },
  },
  methods: {
    animate(a, b) {
      this.anim.show();
      this.anim.playSegments([a, b], true);
      this.anim.onComplete = () => {
        this.anim.show();
      };
    },
  },
  mounted() {
    this.frogsSliderMaxSteps = this.plannedFrogsBackend.toString();
    this.tasksSliderMaxSteps = this.plannedTasksBackend.toString();
    this.frogsSliderValue = this.finishedFrogsToday.toString();
    this.tasksSliderValue = this.finishedTasksBackend.toString();
    this.anim = lottie.loadAnimation({
      container: this.$refs.imageContainer,
      renderer: 'canvas',
      loop: false,
      autoplay: false,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid meet',
      },
      animationData,
    });
    if (!this.skipAnim) {
      setTimeout(() => {
        this.animationState = 'plan_intro';
        setTimeout(() => {
          this.showSliders = true;
        }, 3360);
      }, 2900);
    } else {
      this.animationState = 'skip_intro';
      this.showSliders = true;
    }
  },
  beforeDestroy() {
      this.anim.destroy();
  }
};
</script>

<style scoped>
.Sliders {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.slidersAnimCont {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -47%);
  height: 30em;
  width: 20em;
}
.slidersCont {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 70%;
  width: 100%;
  transform: rotate(270deg);
  clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  transition: clip-path 0.5s ease;
  padding-top: 2em;
}
.slidersCont.active {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  margin-bottom: 6em;
}
.animationWrapper {
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 5%;
}
.animationContainer {
  position: absolute;
  left: 50%;
  top: 25%;
  transform: translate(-50%, -75%);
  width: 41em;
  height: 31.5em;
  pointer-events: none;
}
.tasksSliderContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 5em;
  width: 20em;
  padding-top: 0.2em;
  padding-left: 0.3em;
  padding-right: 0.3em;
}
.tasksSlider {
  -webkit-appearance: none;
  height: 1.2em;
  width: 100%;
  border-radius: 20px;
  outline: none;
  opacity: 0.7;
  background: linear-gradient(to right, #6170f75e, #99beff7e);
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  margin: 1em;
}
.tasksSlider:hover {
  opacity: 1;
}
.tasksSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background: #e0fdff;
  cursor: pointer;
}
.tasksSlider::-webkit-slider-runnable-track {
  width: 100%;
  cursor: pointer;
  /* animate: 0.2s; */
  border-radius: 25px;
  border: 0px solid #000101;
}
.tasksSliderTextContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 4em;
  color: rgb(255, 255, 255);
  padding-left: 0.6em;
  padding-right: 0.8em;
}
.tasksSliderText {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 70%;
  opacity: 0.3;
  transform: rotate(90deg);
}
.tasksSliderText.active {
  opacity: 1;
}
.frogsSliderContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 5em;
  width: 20em;
  padding-left: 0.3em;
  padding-right: 0.3em;
}
.frogsSlider {
  -webkit-appearance: none;
  height: 1.2em;
  width: 100%;
  border-radius: 20px;
  outline: none;
  background: linear-gradient(to right, #9b61f75e, #99beff7e);
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  margin: 1em;
}
.frogsSlider:hover {
  opacity: 1;
}
.frogsSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background: #e0fdff;
  cursor: pointer;
}
.frogsSlider::-webkit-slider-runnable-track {
  width: 100%;
  cursor: pointer;
  /* animate: 0.2s; */
  border-radius: 25px;
  border: 0px solid #000101;
  overflow: hidden;
}
.frogsSliderTextContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 4em;
  color: rgb(255, 255, 255);
  padding-left: 0.6em;
  padding-right: 0.8em;
}
.frogsSliderText {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 60%;
  opacity: 0.3;
  transform: rotate(90deg);
}
.frogsSliderText.active {
  opacity: 1;
}
.iconsText {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: rgba(245, 255, 255, 0.856);
  font-weight: 500;
  font-size: 100%;
  padding-bottom: 1em;
}
.tasksText {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 34.5%;
}
.frogsText {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 34%;
}
@media (max-height: 650px) {
  .frogsSliderContainer {
    width: 15em;
  }
  .tasksSliderContainer {
    width: 15em;
  }
  .animationWrapper {
    position: absolute;
    width: 100%;
    height: 5%;
    bottom: 0.3em;
  }
  .animationContainer {
    left: 46%;
    top: 1em;
    transform: translate(-50%, -75%);
    width: 34em;
    height: 24.5em;
    pointer-events: none;
  }
  .slidersAnimCont {
    top: 53%;
    left: 50%;
    height: 20em;
    width: 18em;
  }
  .slidersCont {
    position: absolute;
    height: 80%;
    width: 100%;
    padding-top: 0em;
    bottom: 0.9em;
  }
  .iconsText {
    position: absolute;
    width: 95%;
    font-weight: 500;
    font-size: 100%;
    padding-bottom: 0em;
    bottom: 1em;
  }
  .tasksText {
    width: 30%;
  }
  .frogsText {
    width: 44%;
  }
}
</style>