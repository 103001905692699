<template>
  <div class="DiaryAnimationContainer">
    <div
      ref="imageContainer"
      class="animContainer" />
  </div>
</template>

<script>
import animationData from '../../assets/diary_mentalhygiene.json'
import lottie from "lottie-web";

export default {
    props: {
        currentStep: Number,
        skipAnim: Boolean
    },
    data(){
        return {
            animationState: 'initial',
        }
    },
    watch: {
      pageTurn(v){
          if(v==1){
              this.animationState = 'page_right'
          }
      },
      currentStep: function(newVal, oldVal){
        if(oldVal<newVal){
            if (oldVal!==-1) {
                this.animationState = 'page_right'
                setTimeout(() => {
                    this.animationState = ''
                }, 1);
            } else if (oldVal===-1 && this.animationState==='skip_intro') {
                this.animationState = 'page_right'
                setTimeout(() => {
                    this.animationState = ''
                }, 1);
            }   
        } else if(oldVal>newVal){
                this.animationState = 'page_left'
                setTimeout(() => {
                    this.animationState = ''
                }, 1);
        }
      },
      animationState(v){
      if(v==='skip_intro'){
          this.animate(369,370)
      } else if (v==='diary_intro'){
          this.animate(0,370)
      } else if (v==='page_right'){
          this.animate(375,449)
      } else if (v==='page_left'){
          this.animate(449,515)
      }
      },
    },
    methods: {
        animate(a,b){
        this.anim.show()
            this.anim.playSegments([a, b], true);
            this.anim.onComplete = ()  => {
                this.anim.show()
            }
        },
    },
    mounted(){
        this.anim = lottie.loadAnimation({
        container: this.$refs.imageContainer,
        renderer: "canvas",
        loop: false,
        autoplay: false,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid meet'
        },
        animationData,
        });
        if(!this.skipAnim){
         setTimeout(() => {
             this.animationState='diary_intro'
             setTimeout(() => {
                 this.showSliders = true
             }, 5360);
         }, 3000)
        } else {
            this.animationState='skip_intro'
            this.showSliders = true
        }
    },
    beforeDestroy() {
        this.anim.destroy();
    }
}
</script>

<style scoped>
.animContainer {
  
  width: 49em;
  height: 62em;
  padding-top: 0em;
  margin-bottom: 12em;
}
@media screen and (min-width: 480px) {
    .animContainer {
        width: 60em;
        height: 78em;
        margin-bottom: 12em;
    }
}
@media (max-height: 650px) {
  .animContainer {
        width: 40em;
        height: 53em;
        margin-bottom: 10em;
    }
}
</style>