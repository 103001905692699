<template>
  <div class="DiaryCard">
    <div
      v-show="showingButtons"
      class="titleContainer">
      <div
        v-show="showingButtons"
        class="categoryTitle">
        {{ $t('categoryTitle') }}
      </div>
      <div class="title">
        {{ $t('title') }}
      </div>
    </div>
    <DiaryAnimation
      class="diaryAnimation"
      :skip-anim="skipAnim"
      :current-step="currentStep" />
    <DiaryTextField
      ref="diaryTextField"
      class="diaryTextField"
      v-show="showingButtons"
      @enableButtons="enableButtons()"
      @disableButtons="disableButtons()"
      @post="postText()"
      :current-step="currentStep" />
    <div
      v-show="showingButtons"
      class="buttons">
      <div class="stepsCountText">
        step {{ currentStep+1 }}/4
      </div>
      <div class="prNextButtonsContainer">
        <div
          :class="{active:!buttonsDisabled && currentStep>0}"
          @click="previousButton()"
          class="previousButton">
          <img
            src="../assets/buttons/previous_button_mentalhygiene.svg"
            height="60px"
            width="60px">
        </div>
        <div
          :class="{active:!buttonsDisabled && currentStep<3}"
          @click="nextButton()"
          class="nextButton">
          <img
            src="../assets/buttons/next_button_mentalhygiene.svg"
            height="60px"
            width="60px">
        </div>
      </div>
      <div
        @click="done()"
        :class="{active:currentStep===3 && !buttonsDisabled}"
        class="doneButton">
        <!-- <img src="../assets/buttons/done_button_mentalhygiene.svg" height="60px" width="60px"> -->
        {{ $t('doneButton') }}
      </div>
    </div>
  </div>
</template>

<translations>
  doneButton: 'Done'
  doneButton_no: 'Ferdig'

  categoryTitle: 'JOURNALING'
  categoryTitle_no: 'DAGBOKSKRIVING'
  title: 'What have you learned today?'
  title_no: 'Hva har du lært i dag?'
</translations>

<script>
import DiaryTextField from '../components/diaryCardComponents/DiaryTextField.vue'
import DiaryAnimation from '../components/diaryCardComponents/DiaryAnimation.vue'
import { TweenMax } from 'gsap'

export default {
    components: {
        DiaryTextField,
        DiaryAnimation,
    },
    props: {
      skipAnim: Boolean
    },
    data() {
      return {
        currentStep: -1,
        showingButtons: false,
        buttonsDisabled: false,
      }
    },
    mounted() {
      if(!this.skipAnim){
        setTimeout(() => {
          this.showingButtons = true
          this.currentStep = 0
        }, 5050);
      } else {
        this.showingButtons = true
        this.currentStep = 0
      }
    },
    methods: {
      disableButtons(){
          this.buttonsDisabled = true
      },
      enableButtons(){
          this.buttonsDisabled = false
      },
      postText(){
        if(this.currentStep<3){
          this.currentStep++
        }
        TweenMax.from(this.$refs['diaryTextField'].$el, 1, {scale:1, opacity:1}).duration(0);
        TweenMax.to(this.$refs['diaryTextField'].$el, 1, {scale:0.95, opacity:0.4, delay:0}).duration(0.1);
        TweenMax.to(this.$refs['diaryTextField'].$el, 1, {scale:1, opacity:1, delay:0.1}).duration(0.5);
     },
      previousButton(){
        if(this.currentStep>0 && !this.buttonsDisabled){
          this.currentStep-- 
          TweenMax.from(this.$refs['diaryTextField'].$el, 1, {x:0, y:0, opacity:1}).duration(0);
          TweenMax.to(this.$refs['diaryTextField'].$el, 1, {x:-10, y:0, opacity:0, delay:0}).duration(0.1);
          TweenMax.to(this.$refs['diaryTextField'].$el, 1, {x:0, y:0, opacity:1, delay:0.1}).duration(1);
        }
      },
      nextButton(){
        if(this.currentStep<3 && !this.buttonsDisabled) {
          this.currentStep++
          TweenMax.from(this.$refs['diaryTextField'].$el, 1, {x:0, y:0, opacity:1}).duration(0);
          TweenMax.to(this.$refs['diaryTextField'].$el, 1, {x:10, y:0, opacity:0, delay:0}).duration(0.1);
          TweenMax.to(this.$refs['diaryTextField'].$el, 1, {x:0, y:0, opacity:1, delay:0.1}).duration(1);
        }
      },
      done(){
        if(this.currentStep===3){
          this.$emit('completed')
        }
      }
    }
  }
</script>

<style scoped>
.DiaryCard {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.diaryAnimation {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  pointer-events: none;
  z-index: -1;
}
.titleContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  height: 4em;
  padding-left: 1em;
  padding-right: 1em;
  top: 3em;
}
.categoryTitle {
  color: rgba(240, 248, 255, 0.418);
  font-size: 100%;
}
.title {
  color: rgba(255, 255, 255, 0.918);
  font-size: 210%;
  font-weight: 600;
}
.buttons {
  /* border: 1px solid red; */
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  bottom: 1em;
  padding-left: 1em;
  padding-right: 2em;
  /* visibility: hidden; */
}
.prNextButtonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 10em;
}
.doneButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5em;
  width: 5em;
  height: 5em;
  opacity: .5;
  background-color: rgb(100, 203, 225);
  color: white;
  font-size: 12px;
}
.doneButton.active {
  opacity: 1;
}
.previousButton {
  opacity: .5;
}
.previousButton.active {
  opacity: 1;
}
.nextButton {
  opacity: .5;
}
.nextButton.active {
  opacity: 1;
}
.doneButton:hover {
  cursor: pointer;
}
.previousButton:hover {
  cursor: pointer;
}
.nextButton:hover {
  cursor: pointer;
}
.stepsCountText {
  color: rgba(255, 255, 255, 0.719);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 620px) {
.titleContainer {
    height: 3.4em;
    padding-left: 1em;
    padding-right: 1em;
    top: 1em;
}
.categoryTitle {
    font-size: 85%;
}
.title {
    font-size: 160%;
}
}
</style>
