<template>
  <div class="ProdCheckContainer">
    <div class="sliderContainer">
      <div class="imageSliderContainer">
        <!-- <div
          ref="imageContainer"
          class="photoFrame"
        /> -->
        <img
          v-show="currentStep===2 && sliderValue==='0'"
          class="svgFrame"
          src="../../assets/castle_1.svg">
        <img
          v-show="currentStep===2 && sliderValue==='1'"
          class="svgFrame"
          src="../../assets/castle_2.svg">
        <img
          v-show="currentStep===2 && sliderValue==='2'"
          class="svgFrame"
          src="../../assets/castle_3.svg">

        <img
          v-show="currentStep===1 && sliderValue==='0'"
          class="svgFrame"
          src="../../assets/bricks_1.svg">
        <img
          v-show="currentStep===1 && sliderValue==='1'"
          class="svgFrame"
          src="../../assets/bricks_2.svg">
        <img
          v-show="currentStep===1 && sliderValue==='2'"
          class="svgFrame"
          src="../../assets/bricks_3.svg">
        <input
          v-model="sliderValue"
          type="range"
          min="0"
          max="2"
          step="1"
          class="slider">
        <div class="sliderTextContainer">
          <div
            :class="{active:sliderValue==='0'}"
            class="sliderText1">
            {{ $t(sliderText1) }}
          </div>
          <div
            :class="{active:sliderValue==='1'}"
            class="sliderText2">
            {{ $t(sliderText2) }}
          </div>
          <div
            :class="{active:sliderValue==='2'}"
            class="sliderText3">
            {{ $t(sliderText3) }}
          </div>
        </div>
      </div>
    </div>
  </div>  
</template>

<translations>
  sliderText1Productivity: 'Distracted'
  sliderText1Productivity_no: 'Distrahert'
  sliderText2Productivity: 'Good'
  sliderText2Productivity_no: 'Bra'
  sliderText3Productivity: 'Flow'
  sliderText3Productivity_no: 'Flow'
  sliderText1Purpose: 'Poor'
  sliderText1Purpose_no: 'Lite'
  sliderText2Purpose: 'Good'
  sliderText2Purpose_no: 'Passe'
  sliderText3Purpose: 'Great'
  sliderText3Purpose_no: 'Veldig'
</translations>

<script>
import animationData from '../../assets/focus_check.json'
import lottie from "lottie-web";

export default {
  props: {
   currentStep: Number,
   skipAnim: Boolean,
   productivity: Number,
   purpose: Number,
  },
  data(){
    return {
      sliderValue: '1',
      animationState: 'work_focus_good',
      productiveAnimationState: 'work_focus_good',
      purposeAnimationState: 'inner_dialog_neutral',
      sliderText1: 'sliderText1Productivity',
      sliderText2: 'sliderText2Productivity',
      sliderText3: 'sliderText3Productivity',
    }
  },
  watch: {
    sliderValue(v){
      if(this.currentStep===1){
        this.$emit('setProductivity', v)
        if(v==='0'){
          this.animationState = 'work_focus_distracted'
        }else if(v==='1'){
          this.animationState = 'work_focus_good'
        }else if(v==='2'){
          this.animationState = 'work_focus_flow'
        }
        this.productiveAnimationState = this.animationState
      }else if(this.currentStep===2){
        this.$emit('setPurpose', v)
        if(v==='0'){
          this.animationState = 'inner_dialog_negative'
        }else if(v==='1'){
          this.animationState = 'inner_dialog_neutral'
        }else if(v==='2'){
          this.animationState = 'inner_dialog_positive'
        }
        this.purposeAnimationState = this.animationState
      }
    },
    animationState(v){
      if (v==='work_focus_good'){
         this.animate(200,400)
       } else if (v==='work_focus_flow'){
         this.animate(500,750)
       } else if (v==='work_focus_distracted'){
         this.animate(800,801)
       } else if (v==='inner_dialog_positive'){
          this.animate(1200,1516)
        } else if (v==='inner_dialog_negative'){
          this.animate(1650,1651)
        } else if (v==='inner_dialog_neutral'){
          this.animate(1570,1571)
       }
    },
    currentStep(v){
      if(v===1){
        this.sliderValue = this.productivity.toString()
        this.animationState = this.productiveAnimationState
        this.sliderText1 = 'sliderText1Purpose'
        this.sliderText2 = 'sliderText2Purpose'
        this.sliderText3 = 'sliderText3Purpose'
      }else if(v===2){
        this.sliderValue = this.purpose.toString()
        if(this.sliderValue==='0'){
          this.animationState = 'inner_dialog_negative'
        }else if(this.sliderValue==='1'){
          this.animationState = 'inner_dialog_neutral'
        }else if(this.sliderValue==='2'){
          this.animationState = 'inner_dialog_positive'
        }
        this.sliderText1 = 'sliderText1Purpose'
        this.sliderText2 = 'sliderText2Purpose'
        this.sliderText3 = 'sliderText3Purpose'
      }
    }
  },
  methods: {
    animate(a,b){
      // this.anim.show()
      //    this.anim.playSegments([a, b], true);
      //    this.anim.onComplete = ()  => {
      //        this.anim.show()
      //    }
      }
  },
  computed: {
  
  },
  mounted(){
    this.sliderValue = this.productivity.toString()
    // this.anim = lottie.loadAnimation({
    // container: this.$refs.imageContainer,
    // renderer: "canvas",
    // loop: true,
    // autoplay: false,
    // rendererSettings: {
    //     preserveAspectRatio: 'xMidYMid meet'
    // },
    // animationData,
    // });
    // this.anim.show()
    //      this.anim.playSegments([200, 400], true);
    //      this.anim.onComplete = ()  => {
    //         this.anim.show()
    // }
  }
}
</script>

<style scoped>
.ProdCheckContainer{
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.sliderContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 70%;
  width: 100%;
}
.imageSliderContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 22em;
  bottom: 0;
}
/* .photoFrame {
  height: 70%;
  width: 50em;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
} */
.svgFrame {
  /* max-width: 70em; */
  height: 60%;
  width: 50em;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
}
.slider {
  -webkit-appearance: none;
  height: 0.6em;
  width: 100%;
  border-radius: 5px;  
  background: #d3d3d369;
  outline: none;
  opacity: .7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%; 
  background: #23dfec;
  cursor: pointer;
}
.slider::-moz-range-thumb {
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  background: #4CAF50;
  cursor: pointer;
}
.sliderTextContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 30em;
  height: 4em;
  color: rgb(255, 255, 255);
}
.sliderText1 {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6em;
  left: -1.9em;
  opacity: 0.5;
}
.sliderText1.active {
  opacity: 1;
}
.sliderText2 {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6em;
  opacity: 0.5;
}
.sliderText2.active {
  opacity: 1;
}
.sliderText3 {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 6em;
  right: -1.9em;
  opacity: 0.5;
}
.sliderText3.active {
  opacity: 1;
}
</style>