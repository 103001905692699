<template>
    <div class="PlanCard">
        <div v-show="showingButtons" class="titleContainer">
            <div ref="categoryTitle" class="categoryTitle">
                {{ $t(categoryTitle) }}
            </div>
            <div ref="title" class="title">
                {{ $t(title) }}
            </div>
        </div>
        <keep-alive>
            <component
                ref="components"
                :is="component[currentStep]"
                :skip-anim="skipAnim"
                :current-step="currentStep"
                :finished-tasks="finishedTasks"
                :finished-frogs="finishedFrogs"
                :productivity="productivity"
                :purpose="purpose"
                :planned-tasks="plannedTasks"
                :planned-frogs="plannedFrogs"
                :play-anim="playTransScreen"
                @setFinishedFrogs="setFinishedFrogs"
                @setFinishedTasks="setFinishedTasks"
                @setProductivity="setProductivity"
                @setPurpose="setPurpose"
                @setPlannedFrogs="setPlannedFrogs"
                @setPlannedTasks="setPlannedTasks"
                @tomorrowsTitle="changeTransScreenTitle()"
            />
        </keep-alive>
        <div v-show="showingButtons" class="buttons">
            <div class="stepsCountText"> step {{ stepCount }}/4 </div>
            <div class="prNextButtonsContainer">
                <div @click="currentStep > 0 ? stepBack() : false" class="previousButton">
                    <img src="../assets/buttons/previous_button_mentalhygiene.svg" height="60px" width="60px" />
                </div>
                <div
                    :class="{ done: currentStep > 3 }"
                    @click="currentStep < 4 ? stepForward() : false"
                    class="nextButton"
                >
                    <img src="../assets/buttons/next_button_mentalhygiene.svg" height="60px" width="60px" />
                </div>
            </div>
            <div @click="done()" :class="{ active: currentStep === 4 }" class="doneButton">
                <!-- <img src="../assets/buttons/done_button_mentalhygiene.svg" height="60px" width="60px"> -->
                {{ $t('doneButton') }}
            </div>
        </div>
    </div>
</template>

<translations>
  categoryTitleProductivity: 'PRODUCTIVITY'
  categoryTitleProductivity_no: 'PRODUKTIVITET'
  categoryTitlePurpose: 'PURPOSE'
  categoryTitlePurpose_no: 'MENING'
  categoryTitlePlanning: 'PLANNING'
  categoryTitlePlanning_no: 'PLANLEGGING'
  
  title1: 'How much of your daily plan did you finish?'
  title1_no: 'Hvor mye av dagsplanen ble du ferdig med?'
  title2: 'How productive were you?'
  title2_no: 'Hvor produktiv har du vært?'
  title3: 'How meaningful was your day?'
  title3_no: 'Hvor meningsfull har dagen vært?'
  title4-1: 'Yay, you finished reviewing your day!'
  title4-1_no: 'Yes, du evaluerte dagen'
  title4-2: "Now, let's make tomorrow great!"
  title4-2_no: 'Nå, lag planen for å rocke morgendagen!'
  title5: 'Plan your tasks and frogs for tomorrow'
  title5_no: 'Planlegg oppgavene og froskene for i morgen!'

  doneButton: 'Done'
  doneButton_no: 'Ferdig'
</translations>

<script>
import Sliders from '../components/planCardComponents/Sliders.vue';
import ProdCheck from '../components/planCardComponents/ProductivityCheck.vue';
import TransitioningScreen from '../components/planCardComponents/TransitioningScreen.vue';
import { TweenMax } from 'gsap';
import moment from 'moment';

export default {
    components: {
        sliders: Sliders,
        prodCheck: ProdCheck,
        transitioningScreen: TransitioningScreen,
    },
    props: {
        skipAnim: Boolean,
    },
    data() {
        return {
            component: ['sliders', 'prodCheck', 'prodCheck', 'transitioningScreen', 'sliders'],
            currentStep: 0,
            showingButtons: false,
            categoryTitle: 'categoryTitleProductivity',
            title: 'title1',
            finishedTasks: 0,
            productivity: 1,
            purpose: 1,
            plannedTasks: 0,
            plannedFrogs: 0,
            stepCount: 1,
            playTransScreen: false,
            finishedFrogs: 0,
        };
    },
    computed: {
        plannedFrogsBackend() {
            const dayplan = this.$store.state.moduleMentalhygiene.dayplanToday;
            return dayplan ? dayplan.plannedFrogs : 0;
        },
        plannedTasksBackend() {
            const dayplan = this.$store.state.moduleMentalhygiene.dayplanToday;
            return dayplan ? dayplan.plannedTasks : 0;
        },
        finishedTasksBackend() {
            const dayplan = this.$store.state.moduleMentalhygiene.dayplanToday;
            return dayplan ? dayplan.finishedTasks : 0;
        },
        purposeBackend() {
            const dayplan = this.$store.state.moduleMentalhygiene.dayplanToday;
            return dayplan ? dayplan.purpose : 1;
        },
        productivityBackend() {
            const dayplan = this.$store.state.moduleMentalhygiene.dayplanToday;
            return dayplan ? dayplan.productivity : 1;
        },
        finishedFrogsToday() {
            const dayplan = this.$store.getters['moduleMentalhygiene/dayplanToday'];
            return dayplan ? dayplan.finishedFrogs : 0;
        },
    },
    watch: {
        currentStep(v) {
            if (v === 0) {
                (this.categoryTitle = 'categoryTitleProductivity'), (this.title = 'title1');
                this.stepCount = 1;
            } else if (v === 1) {
                (this.categoryTitle = 'categoryTitleProductivity'), (this.title = 'title2');
                this.stepCount = 2;
            } else if (v === 2) {
                (this.categoryTitle = 'categoryTitlePurpose'), (this.title = 'title3');
                this.stepCount = 3;
            } else if (v === 3) {
                (this.categoryTitle = 'categoryTitlePlanning'), (this.title = 'title4-1');
                this.stepCount = 4;
                this.playTransScreen = true;
                setTimeout(() => {
                    this.playTransScreen = false;
                }, 1);
            } else if (v === 4) {
                (this.categoryTitle = 'categoryTitleProductivity'), (this.title = 'title5');
                this.stepCount = 4;
            }
        },
    },
    mounted() {
        this.finishedFrogs = this.finishedFrogsToday
        this.plannedTasks = this.plannedTasksBackend;
        this.plannedFrogs = this.plannedFrogsBackend;
        this.purpose = this.purposeBackend;
        this.productivity = this.productivityBackend;
        this.finishedTasks = this.finishedTasksBackend;
        if (!this.skipAnim) {
            setTimeout(() => {
                this.showingButtons = true;
                TweenMax.from(this.$refs.categoryTitle, 1, { scale: 1, opacity: 0.5, delay: 0 }).duration(0);
            }, 4300);
        } else {
            this.showingButtons = true;
            TweenMax.from(this.$refs.categoryTitle, 1, { scale: 1, opacity: 0.5, delay: 0 }).duration(0);
        }
    },
    methods: {
        changeTransScreenTitle() {
            if (this.currentStep === 3) {
                this.title = 'title4-2';
                TweenMax.from(this.$refs.title, 1, { scale: 1, opacity: 0, ease: Circ.ease }).duration(0);
                TweenMax.to(this.$refs.title, 1, { scale: 1.1, opacity: 1, delay: 0, ease: Circ.ease }).duration(0.1);
                TweenMax.to(this.$refs.title, 1, { scale: 0.95, opacity: 0.5, delay: 0.1, ease: Circ.ease }).duration(
                    0.1
                );
                TweenMax.to(this.$refs.title, 1, { scale: 1, opacity: 1, delay: 0.2, ease: Circ.ease }).duration(0.1);
            }
        },
        stepBack() {
            this.currentStep--;
            TweenMax.killAll();
            setTimeout(() => {
                TweenMax.from(this.$refs['components'].$el, 1, { x: -200, opacity: 1, ease: Circ.ease }).duration(0);
                TweenMax.to(this.$refs['components'].$el, 1, {
                    x: 20,
                    opacity: 0.6,
                    delay: 0,
                    ease: Circ.ease,
                }).duration(0.2);
                TweenMax.to(this.$refs['components'].$el, 1, {
                    x: 0,
                    opacity: 1,
                    delay: 0.2,
                    ease: Circ.ease,
                }).duration(0.5);

                TweenMax.from(this.$refs.categoryTitle, 1, { scale: 1, opacity: 0, ease: Circ.ease }).duration(0);
                TweenMax.to(this.$refs.categoryTitle, 1, { scale: 2, opacity: 1, delay: 0, ease: Circ.ease }).duration(
                    0.1
                );
                TweenMax.to(this.$refs.categoryTitle, 1, {
                    scale: 0.95,
                    opacity: 0.5,
                    delay: 0.5,
                    ease: Circ.ease,
                }).duration(0.1);
                TweenMax.to(this.$refs.categoryTitle, 1, {
                    scale: 1,
                    opacity: 0.5,
                    delay: 0.6,
                    ease: Circ.ease,
                }).duration(0.1);
                TweenMax.from(this.$refs.title, 1, { opacity: 0 }).duration(0);
                TweenMax.to(this.$refs.title, 1, { opacity: 1, delay: 0.6 }).duration(1);
            }, 10);
        },
        stepForward() {
            this.currentStep++;
            TweenMax.killAll();
            setTimeout(() => {
                TweenMax.from(this.$refs['components'].$el, 1, { x: 200, opacity: 1 }).duration(0);
                TweenMax.to(this.$refs['components'].$el, 1, { x: -20, opacity: 0.6, delay: 0 }).duration(0.2);
                TweenMax.to(this.$refs['components'].$el, 1, { x: 0, opacity: 1, delay: 0.2 }).duration(0.5);

                TweenMax.from(this.$refs.categoryTitle, 1, { scale: 1, opacity: 0, ease: Circ.ease }).duration(0);
                TweenMax.to(this.$refs.categoryTitle, 1, { scale: 2, opacity: 1, delay: 0, ease: Circ.ease }).duration(
                    0.1
                );
                TweenMax.to(this.$refs.categoryTitle, 1, {
                    scale: 0.95,
                    opacity: 0.5,
                    delay: 0.5,
                    ease: Circ.ease,
                }).duration(0.1);
                TweenMax.to(this.$refs.categoryTitle, 1, {
                    scale: 1,
                    opacity: 0.5,
                    delay: 0.6,
                    ease: Circ.ease,
                }).duration(0.1);
                TweenMax.from(this.$refs.title, 1, { opacity: 0 }).duration(0);
                TweenMax.to(this.$refs.title, 1, { opacity: 1, delay: 0.6 }).duration(1);
            }, 10);
        },
        setPlannedFrogs(f) {
            this.plannedFrogs = parseInt(f);
        },
        setPlannedTasks(t) {
            this.plannedTasks = parseInt(t);
        },
        setFinishedFrogs(f) {
            this.finishedFrogs = f
        },
        setFinishedTasks(t) {
            this.finishedTasks = parseInt(t);
        },
        setProductivity(p) {
            this.productivity = parseInt(p);
        },
        setPurpose(p) {
            this.purpose = parseInt(p);
        },

        done() {
            if (this.currentStep === 4) {
                this.$emit('completed');
                const today = moment();
                const dayplan = this.$store.getters['moduleMentalhygiene/dayplanToday'];
                if (dayplan) {
                    this.$store.dispatch('moduleMentalhygiene/updateDayplan', {
                        nodeId: dayplan.nodeId,
                        finishedTasks: this.finishedTasks,
                        finishedFrogs: this.finishedFrogs,
                        productivity: this.productivity,
                        purpose: this.purpose,
                        plannedFrogs: this.plannedFrogs,
                        plannedTasks: this.plannedTasks,
                    });
                } else {
                    this.$store.dispatch('moduleMentalhygiene/createDayplan', {
                        finishedTasks: this.finishedTasks,
                        finishedFrogs: this.finishedFrogs,
                        productivity: this.productivity,
                        purpose: this.purpose,
                        plannedFrogs: this.plannedFrogs,
                        plannedTasks: this.plannedTasks,
                        date: today,
                    });
                }
            }
        },
    },
};
</script>

<style scoped>
.PlanCard {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.titleContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    height: 4em;
    padding-left: 1em;
    padding-right: 1em;
    top: 3em;
}
.categoryTitle {
    color: rgb(240, 248, 255);
    font-size: 100%;
}
.title {
    color: rgba(255, 255, 255, 0.918);
    font-size: 210%;
    font-weight: 600;
}
.buttons {
    /* border: 1px solid red; */
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    bottom: 1em;
    padding-left: 1em;
    padding-right: 2em;
    /* visibility: hidden; */
}
.prNextButtonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 10em;
}
.doneButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 5em;
    width: 5em;
    height: 5em;
    opacity: 0.5;
    background-color: rgb(100, 203, 225);
    color: white;
    font-size: 12px;
}
.doneButton.active {
    opacity: 1;
}
.doneButton:hover {
    cursor: pointer;
}
.previousButton:hover {
    cursor: pointer;
}
.nextButton:hover {
    cursor: pointer;
}
.nextButton {
    opacity: 1;
}
.nextButton.done {
    opacity: 0.5;
}
.stepsCountText {
    color: rgba(255, 255, 255, 0.719);
    display: flex;
    flex-direction: column;
    justify-content: center;
}
@media (max-width: 620px) {
.titleContainer {
    height: 3.4em;
    padding-left: 1em;
    padding-right: 1em;
    top: 1em;
}
.categoryTitle {
    font-size: 85%;
}
.title {
    font-size: 160%;
}
}
</style>
