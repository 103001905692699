<template>
  <div class="FocusContainer">
    <div
      v-show="showingButtons"
      class="titleContainer">
      <div
        ref="categoryTitle"
        v-show="showingButtons"
        class="categoryTitle">
        {{ $t(focusCategory) }}
      </div>
      <div
        ref="title"
        class="title">
        {{ $t(focusTitle) }}
      </div>
    </div>
    <div 
      class="submittedFocusContainer1"
      :class="{visible:focusSubmitted && workFocus === 'Good' && focusStep === 1 || focusSubmitted && workFocus === 'Flow' && focusStep === 1 || focusSubmitted && innerDialog === 'Neutral' && focusStep === 2 || focusSubmitted && innerDialog === 'Positive' && focusStep === 2}">
      <img
        src="../assets/checkmark_submitted.png"
        height="150px"
        width="150px">
      <h1 class="submittedText">
        {{ submittedText }}
      </h1>
      <h3 class="addNoteText">
        Add Note
      </h3>
    </div>
    <div 
      class="submittedFocusContainer2"
      :class="{visible:focusSubmitted && workFocus === 'Distracted' && focusStep === 1}">
      <div class="submittedText">
        {{ $t('distractionTitleWork') }}
      </div>
      <div
        @click="distractionWork=1"
        :class="{active:distractionWork===1}"
        class="submitionQuizButton">
        {{ $t('distration1Work') }}
      </div>
      <div
        @click="distractionWork=2"
        :class="{active:distractionWork===2}"
        class="submitionQuizButton">
        {{ $t('distration2Work') }}
      </div>
      <div
        @click="distractionWork=3"
        :class="{active:distractionWork===3}"
        class="submitionQuizButton">
        {{ $t('distration3Work') }}
      </div>
    </div>
    <div 
      class="submittedFocusContainer3"
      :class="{visible:focusSubmitted && innerDialog === 'Negative' && focusStep === 2}">
      <div class="submittedText">
        {{ $t('distractionTitleMind') }}
      </div>
      <div
        @click="distractionMind=1"
        :class="{active:distractionMind===1}"
        class="submitionQuizButton">
        {{ $t('distration1Mind') }}
      </div>
      <div
        @click="distractionMind=2"
        :class="{active:distractionMind===2}"
        class="submitionQuizButton">
        {{ $t('distration2Mind') }}
      </div>
      <div
        @click="distractionMind=3"
        :class="{active:distractionMind===3}"
        class="submitionQuizButton">
        {{ $t('distration3Mind') }}
      </div>
    </div>
    <div 
      class="submittedFocusContainer4"
      :class="{visible:focusSubmitted && focusStep === 3}">
      <div
        ref="postureContainer"
        class="postureContainer">
        <div
          @click="selectPosture(0)"
          class="postureButton"
          :class="{active:postureFocus===0}">
          <img
            class="posture_image"
            src="../assets/posture_1_img.png">
        </div>
        <div
          @click="selectPosture(1)"
          class="postureButton"
          :class="{active:postureFocus===1}">
          <img
            class="posture_image"
            src="../assets/posture_2_img.png">
        </div>
        <div
          @click="selectPosture(2)"
          class="postureButton"
          :class="{active:postureFocus===2}">
          <img
            class="posture_image"
            src="../assets/posture_3_corr_img.png">
        </div>
      </div>
    </div>
    <div 
      class="submittedFocusContainer5"
      :class="{visible:focusSubmitted && focusStep === 4}">
      <img
        class="submittedPostureImage"
        v-if="postureFocus===0"
        src="../assets/posture_1_img.png"
        height="250px">
      <img
        class="submittedPostureImage"
        v-if="postureFocus===1"
        src="../assets/posture_2_img.png"
        height="250px">
      <img
        class="submittedPostureImage"
        v-if="postureFocus===2"
        src="../assets/posture_3_corr_img.png"
        height="250px">
      <h1 class="submittedText">
        Your posture is...
      </h1>
    </div>
    <div
      ref="sliderContainer"
      :class="{visible:!focusSubmitted}"
      class="sliderContainer">
      <div class="imageSliderContainer">
        <div
          ref="imageContainer"
          class="photoFrame"
          :class="{visible:!focusSubmitted}" />
        <input
          v-model="sliderValue"
          type="range"
          min="0"
          max="2"
          step="1"
          class="slider">
        <div class="sliderTextContainer">
          <div
            :class="{active:sliderValue==='0'}"
            class="sliderText1">
            {{ $t(sliderText1) }}
          </div>
          <div
            :class="{active:sliderValue==='1'}"
            class="sliderText2">
            {{ $t(sliderText2) }}
          </div>
          <div
            :class="{active:sliderValue==='2'}"
            class="sliderText3">
            {{ $t(sliderText3) }}
          </div>
        </div>
      </div>
    </div>
    <div
      :class="{disabled:!showingButtons}"
      class="buttons"> 
      <!-- <div class="share">
        <img src="../assets/buttons/share_button_mentalhygiene.svg" height="60px" width="60px">
      </div> -->
      <div
        class="nextFocusButton"
        :class="{active:nextButtonActive}"
        @click="submitFocus()">
        <img
          v-if="focusSubmitted && focusStep<3"
          src="../assets/buttons/next_button_mentalhygiene.svg"
          height="60px"
          width="60px">
        <img
          v-if="!focusSubmitted && focusStep<4"
          src="../assets/buttons/next_button_mentalhygiene.svg"
          height="60px"
          width="60px">
        <!-- <img v-if="focusSubmitted && focusStep===3" src="../assets/buttons/done_button_mentalhygiene.svg" height="60px" width="60px"> -->
        <div
          v-if="focusSubmitted && focusStep===3"
          class="doneButton">
          {{ $t('doneButton') }}
        </div>
      </div>
    </div>
  </div>
</template>

<translations>
  focusCategoryWork: 'WORK FOCUS'
  focusCategoryWork_no: 'JOBB-FOKUS'
  focusTitleWork: 'How is your day plan turning out?'
  focusTitleWork_no: 'Hvordan utvikler dagsplanen seg?'
  focusCategoryMind: 'MIND FOCUS'
  focusCategoryMind_no: 'TANKE-FOKUS'
  focusTitleMind: 'How is your inner dialogue today?'
  focusTitleMind_no: 'Hvordan er din indre dialog?'
  focusCategoryBody: 'BODY FOCUS'
  focusCategoryBody_no: 'KROPP-FOKUS'
  focusTitleBody: 'How is your body today?'
  focusTitleBody_no: 'Hvordan har kroppen din det'
  focusTitlePosture: 'How is your posture today?'
  focusTitlePosture_no: 'Hvordan er holdningen din?'

  sliderText1Work: 'Distracted'
  sliderText1Work_no: 'Distrahert'
  sliderText2Work: 'Good'
  sliderText2Work_no: 'Bra'
  sliderText3Work: 'Flow'
  sliderText3Work_no: 'Flow'
  sliderText1Mind: 'Negative'
  sliderText1Mind_no: 'Negativ'
  sliderText2Mind: 'Neutral'
  sliderText2Mind_no: 'Nøytral'
  sliderText3Mind: 'Positive'
  sliderText3Mind_no: 'Positiv'
  sliderText1Body: 'Too relaxed'
  sliderText1Body_no: 'For avslappet'
  sliderText2Body: 'Balanced'
  sliderText2Body_no: 'Balansert'
  sliderText3Body: 'Too tense'
  sliderText3Body_no: 'For anspent'

  distractionTitleWork: 'What distracted you?'
  distractionTitleWork_no: 'Hva distraherer deg?'
  distration1Work: 'Personal distractions'
  distration1Work_no: 'Personlige forstyrrelser'
  distration2Work: 'Team distractions'
  distration2Work_no: 'Forstyrrelser fra teamet'
  distration3Work: 'External distractions'
  distration3Work_no: 'Eksterne forstyrrelser'

  distractionTitleMind: 'Do you know why?'
  distractionTitleMind_no: 'Vet du hvorfor?'
  distration1Mind: "I know what's bothering me - fix it now"
  distration1Mind_no: 'Jeg vet hva som plager meg fikser det nå'
  distration2Mind: "I know what's bothering me - fix it later"
  distration2Mind_no: 'Jeg vet hva som plager meg fikser det senere'
  distration3Mind: "No idea what's up - I'll reflect on it later"
  distration3Mind_no: 'Usikker hva som skjer reflekter på det senere'

  doneButton: 'Done'
  doneButton_no: 'Ferdig'
</translations>

<script>
import animationData from '../assets/focus_check.json'
import lottie from "lottie-web";
import { TweenMax } from 'gsap'

export default {
 components: {
     
 },
 props: {
   skipAnim: Boolean
 },
 data(){
   return {
     submittedText: 'You are doing great! Keep it up!',
     focusAnimationState: 'initial', 
     focusStep: 1,
     focusSubmitted: false,
     showingButtons: false,
     sliderValue: '1',
     focusCategory: 'focusCategoryWork',
     focusTitle: 'focusTitleWork',
     workFocus:'',
     innerDialog: '',
     bodyFocus: '',
     postureFocus: -1,
     nextButtonActive: true,
     sliderText1: 'sliderText1Work',
     sliderText2: 'sliderText2Work',
     sliderText3: 'sliderText3Work',
     distractionWork: 0,
     distractionMind: 0,
   };
 },
 computed: {
    focusChecksToday(){
      const focusChecks = this.$store.getters['moduleMentalhygiene/todaysFocusChecks'].slice()
      return focusChecks
    }
 },
 watch: {
   focusStep(v){
     if(v===1){
       this.focusCategory = 'focusCategoryWork'
       this.focusTitle = 'focusTitleWork'
       this.sliderText1 = 'sliderText1Work'
       this.sliderText2 = 'sliderText2Work'
       this.sliderText3 = 'sliderText3Work'
     } else if(v===2){
       this.focusCategory = 'focusCategoryMind'
       this.focusTitle = 'focusTitleMind'
       this.sliderText1 = 'sliderText1Mind'
       this.sliderText2 = 'sliderText2Mind'
       this.sliderText3 = 'sliderText3Mind'
     } else if(v===3){
       this.focusCategory = 'focusCategoryBody'
       this.focusTitle = 'focusTitleBody'
       this.sliderText1 = 'sliderText1Body'
       this.sliderText2 = 'sliderText2Body'
       this.sliderText3 = 'sliderText3Body'
     } 
   },
   sliderValue(v){
     if(this.focusStep===1) {
        if(v==='0'){
          this.workFocus = 'Distracted'
          this.focusAnimationState='work_focus_distracted'
        }else if(v==='1'){
          this.workFocus = 'Good'
          this.focusAnimationState='work_focus_good'
        }else if(v==='2'){
          this.workFocus = 'Flow'
          this.focusAnimationState='work_focus_flow'
        }
     } else if(this.focusStep===2) {
        if(v==='0'){
          this.innerDialog = 'Negative'
          this.focusAnimationState='inner_dialog_negative'
        }else if(v==='1'){
          this.innerDialog = 'Neutral'
          this.focusAnimationState='inner_dialog_neutral'
        }else if(v==='2'){
          this.innerDialog = 'Positive'
          this.focusAnimationState='inner_dialog_positive'
        }
     } else if(this.focusStep===3) {
        if(v==='0'){
          this.focusAnimationState='body_focus_too_relaxed'
        }else if(v==='1'){
          this.focusAnimationState='body_focus_balanced'
        }else if(v==='2'){
          this.focusAnimationState='body_focus_too_tensed'
        }
     }
   },
   focusAnimationState(v){
     if(this.focusStep===1) {
       if (v==='work_focus_initial'){
         this.animate(0,400)
       } else if (v==='work_focus_good'){
         this.animate(200,400)
       }else if (v==='work_focus_flow'){
         this.animate(500,750)
       } else if (v==='work_focus_distracted'){
         this.animate(800,801)
       }
     } else if(this.focusStep===2) {
        if (v==='inner_dialog_positive'){
           this.animate(1200,1516)
        }else if (v==='inner_dialog_negative'){
          this.animate(1650,1651)
        } else if (v==='inner_dialog_neutral'){
          this.animate(1570,1571)
        }
     } else if(this.focusStep===3) {
        if (v==='body_focus_balanced'){
          this.animate(2400,2410)
        }else if (v==='body_focus_too_relaxed'){
          this.animate(2740,2750)
        } else if (v==='body_focus_too_tensed'){
          this.animate(3050,3060)
        }
     }
   }
 },
 mounted(){
   this.distractionWork = 0
   this.distractionMind = 0
   this.anim = lottie.loadAnimation({
    container: this.$refs.imageContainer,
    renderer: "canvas",
    loop: true,
    autoplay: false,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid meet'
    },
    //path: "joy_card_image.json",
    animationData,
    });
    if(!this.skipAnim){
      setTimeout(() => {
          this.focusAnimationState='work_focus_initial'
          setTimeout(() => {
            this.showingButtons = true
            TweenMax.from(this.$refs.categoryTitle, 1, {scale:1, opacity:0.5, delay:0}).duration(0);
          }, 1000)
          setTimeout(() => {
            if(this.focusAnimationState==='work_focus_initial'){
              this.focusAnimationState='work_focus_good'
          }
        }, 6400)
      }, 3350);
    } else {
      this.focusAnimationState='work_focus_initial'
      this.showingButtons = true
      TweenMax.from(this.$refs.categoryTitle, 1, {scale:1, opacity:0.5, delay:0}).duration(0);
      setTimeout(() => {
         if(this.focusAnimationState==='work_focus_initial'){
           this.focusAnimationState='work_focus_good'
         }
      }, 5)
    }
   },
   beforeDestroy() {
    this.anim.destroy();
   },
   methods:{
     transitionAnimation(component){
        TweenMax.killAll()
        setTimeout(() => {
          if(component==='sliderContainer'){
            TweenMax.from(this.$refs.sliderContainer, 1, {x:200, opacity:1}).duration(0);
            TweenMax.to(this.$refs.sliderContainer, 1, {x:-20, opacity:0.6, delay:0}).duration(0.2);
            TweenMax.to(this.$refs.sliderContainer, 1, {x:0, opacity:1, delay:0.2}).duration(0.5);
          } else {
            TweenMax.from(this.$refs.postureContainer, 1, {x:200, opacity:1}).duration(0);
            TweenMax.to(this.$refs.postureContainer, 1, {x:-20, opacity:0.6, delay:0}).duration(0.2);
            TweenMax.to(this.$refs.postureContainer, 1, {x:0, opacity:1, delay:0.2}).duration(0.5);
          }
          TweenMax.from(this.$refs.categoryTitle, 1, {scale:1, opacity:0, ease: Circ.ease}).duration(0);
          TweenMax.to(this.$refs.categoryTitle, 1, {scale:2, opacity:1, delay:0, ease: Circ.ease}).duration(0.1);
          TweenMax.to(this.$refs.categoryTitle, 1, {scale:0.95, opacity:0.5, delay:0.5, ease: Circ.ease}).duration(0.1);
          TweenMax.to(this.$refs.categoryTitle, 1, {scale:1, opacity:0.5, delay:0.6, ease: Circ.ease}).duration(0.1);
          TweenMax.from(this.$refs.title, 1, {opacity:0}).duration(0);
          TweenMax.to(this.$refs.title, 1, {opacity:1, delay:0.6}).duration(1);
        }, 10);
      },
     addFocusCheck(arr, focusType, rating, note){
      if(this.focusChecksToday[arr] && this.focusChecksToday[arr].rating !== rating || this.focusChecksToday[arr] && this.focusChecksToday[arr].note !== note) {
        this.$store.dispatch('moduleMentalhygiene/updateFocusCheck', {focusCheckType: focusType,rating: rating, note: note, id: this.focusChecksToday[arr].nodeId}) 
      } else if(!this.focusChecksToday[arr]){
        this.$store.dispatch('moduleMentalhygiene/createFocusCheck', {focusCheckType: focusType, rating: rating, note: note})
      }
     },
     animate(a,b){
       this.anim.show()
       this.anim.playSegments([a, b], true);
       this.anim.onComplete = ()  => {
           this.anim.show()
       }
    },
     selectPosture(v){
       this.nextButtonActive = true
       this.postureFocus = v
     },
     submitFocus(){
       if(this.nextButtonActive){
          if(this.focusSubmitted === false && this.focusStep === 1){
            this.addFocusCheck(0, 'WORK_FOCUS', parseInt(this.sliderValue), '0')
            if(this.sliderValue==='0'){
              this.workFocus = 'Distracted'
            } else if(this.sliderValue==='1'){
              this.workFocus = 'Good'
            } else if(this.sliderValue==='2'){
              this.workFocus = 'Flow'
            }
            if(this.sliderValue!=='0'){
              this.focusStep++
              this.focusAnimationState = 'inner_dialog_neutral'
              this.sliderValue = '1'
              this.transitionAnimation('sliderContainer')
            } else {
              this.focusSubmitted = true
            }
          } else if(this.focusSubmitted === false && this.focusStep === 2){
              this.addFocusCheck(1, 'INNER_DIALOG', parseInt(this.sliderValue), '0')
              if(this.sliderValue==='0'){
                this.innerDialog = 'Negative'
              } else if(this.sliderValue==='1'){
                this.innerDialog = 'Neutral'
              } else if(this.sliderValue==='2'){
                this.innerDialog = 'Positive'
              }
              if(this.sliderValue!=='0'){
                this.focusStep++
                this.focusAnimationState = 'body_focus_balanced'
                this.sliderValue = '1'
                this.transitionAnimation('sliderContainer')
              } else {
                this.focusSubmitted = true
              }
          } else if(this.focusSubmitted === false && this.focusStep === 3){
            this.addFocusCheck(2, 'BODY_FOCUS', parseInt(this.sliderValue), '0')
            this.nextButtonActive = false
            this.focusSubmitted = true
            this.bodyFocus = 'something'
            this.focusTitle = 'focusTitlePosture'
            this.transitionAnimation('posture')
          } else if(this.focusSubmitted === true && this.focusStep === 1){
            this.addFocusCheck(0, 'WORK_FOCUS', parseInt(this.sliderValue), this.distractionWork.toString())
            this.focusSubmitted = false
            this.focusStep++
            this.focusAnimationState = 'inner_dialog_neutral'
            this.sliderValue = '1'
            this.transitionAnimation('sliderContainer')
          } else if(this.focusSubmitted === true && this.focusStep === 2){
            this.addFocusCheck(1, 'INNER_DIALOG', parseInt(this.sliderValue), this.distractionMind.toString())
            this.focusSubmitted = false
            this.focusStep++
            this.focusAnimationState = 'body_focus_balanced'
            this.sliderValue = '1'
            this.transitionAnimation('sliderContainer')
          } else if(this.focusSubmitted === true && this.focusStep === 3){
            // this.focusStep++
            this.addFocusCheck(3, 'SITTING_POSTURE', this.postureFocus, '0')
            this.$emit('completed')
          } else if(this.focusSubmitted === true && this.focusStep === 4){
            // this.$emit('completed')
          }
       }
     },
   }
}
</script>

<style scoped>
.titleContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  height: 4em;
  padding-left: 1em;
  padding-right: 1em;
  top: 2em;
}
.categoryTitle {
  color: rgb(240, 248, 255);
  font-size: 100%;
}
.title {
  color: rgba(255, 255, 255, 0.918);
  font-size: 210%;
  font-weight: 600;
}
.submittedPostureImage{
  background-color: rgba(75, 35, 168, 0.616);
  padding: 1.5em;
}
.submittedFocusContainer1{
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50em;
  max-width: 40em;
  visibility: hidden;
}
.submittedFocusContainer2{
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50em;
  width: 50em;
  visibility: hidden;
}
.submittedFocusContainer3{
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50em;
  width: 10em;
  visibility: hidden;
}
.submittedFocusContainer4{
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50%;
  max-width: 100%;
  min-width: 50%;
  visibility: hidden;
}
.submittedFocusContainer5{
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50em;
  max-width: 40em;
  visibility: hidden;
}
.postureContainer{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 70%;
  width: 100%;
  padding-left: 0.4em;
  padding-right: 0.4em;
}
.postureButton {
  background-color: rgba(75, 35, 168, 0.616);
  /* border: 1px solid rgba(185, 176, 176, 0.39); */
  display: flex;
  justify-content: center;
  margin: 0.2em;
  padding: 1.5em;
  width: 30%;
  height: 80%;
}
.postureButton.active {
  border: 1px solid rgb(255, 255, 255);
  background-color: rgb(255, 255, 255);
}
.postureButton:hover {
  border: 1px solid rgb(255, 255, 255);
  background-color: rgb(255, 255, 255);
}
.posture_image {
  max-height: 100%;
  padding: 1em;
}
.submitionQuizButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5em;
  width: 25em;
  background-color: rgb(245, 241, 255);
  margin: 0.2em;
  color: rgba(41, 19, 59, 0.842);
  border-radius: 2px;
  vertical-align:middle;
}
.submitionQuizButton:hover {
  background-color: rgba(255, 255, 255, 0.664);
  color: rgba(61, 26, 107, 0.842);
}
.submitionQuizButton.active {
  background-color: rgba(53, 242, 255, 0.664);
  color: rgba(255, 255, 255, 0.932);
}
.submittedText {
  font-weight: 500;
  color: white;
  font-size: 24px;
  padding-top: 2em;
  padding-bottom: 1.4em;
  text-align: center;
  width: 15em;
}
.addNoteText {
  color: rgb(66, 179, 255);
  font-weight: 400;
  font-size: 18px;
  opacity: 0.5;
}
.imageSliderContainer {
   position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 22em;
  bottom: 0;
}
.photoFrame {
  /* max-width: 70em; */
  height: 70%;
  width: 50em;
  visibility: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.FocusContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 1em;
  left: 0;
  bottom: 0;
  right: 0;
}
.buttons {
    /* border: 1px solid red; */
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 22em;
    bottom: 1em;
    /* border: 1px solid red; */
    /* visibility: hidden; */
}
.buttons.disabled{
    visibility: hidden;
}
.share{
    display: flex;
    flex-direction: column;
    /* background: salmon; */
    justify-content: space-between;
    align-items: center;
    margin: auto;
    color: white;
    font-size: 13px;
    font-weight: 600;
    height: 6em;
    width: 5em;
    opacity: 0.5;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.removeFrogButton{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    color: white;
    font-size: 13px;
    font-weight: 600;
    height: 6em;
    width: 9em;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.nextFocusButton{
  /* background: salmon; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    color: white;
    font-size: 13px;
    font-weight: 600;
    height: 6em;
    width: 6em;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    opacity: 0.5;
}
.nextFocusButton.active{
    opacity: 1;
}
.nextFocusButton:hover {
  cursor: pointer;
}
.add.fade{
  opacity: 0.3;
  pointer-events: none;
}
.spacer_main{
    height: 2em;
}
.slider:hover {
  opacity: 1;
}
.sliderContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 70%;
  width: 100%;
  visibility: hidden;
}
.slider {
  -webkit-appearance: none;
  height: 0.6em;
  width: 100%;
  border-radius: 5px;  
  background: #d3d3d369;
  outline: none;
  opacity: .7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%; 
  background: #23dfec;
  cursor: pointer;
}
.slider::-moz-range-thumb {
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  background: #4CAF50;
  cursor: pointer;
}
.sliderTextContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 30em;
  height: 4em;
  color: rgb(255, 255, 255);
}
.sliderText1 {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6em;
  left: -1.9em;
  opacity: 0.5;
}
.sliderText1.active {
  opacity: 1;
}
.sliderText2 {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6em;
  opacity: 0.5;
}
.sliderText2.active {
  opacity: 1;
}
.sliderText3 {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 6em;
  right: -1.9em;
  opacity: 0.5;
}
.sliderText3.active {
  opacity: 1;
}
.visible{
    visibility: visible;
}
.doneButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5em;
  width: 5em;
  height: 5em;
  background-color: rgb(100, 203, 225);
  color: white;
  font-size: 12px;
}
.doneButton:hover {
  cursor: pointer;
}
@media (max-width: 620px) {
.titleContainer {
    height: 3.4em;
    padding-left: 1em;
    padding-right: 1em;
    top: 1em;
}
.categoryTitle {
    font-size: 85%;
}
.title {
    font-size: 160%;
}
.imageSliderContainer {
    bottom: 2em;
  }
}
</style>
